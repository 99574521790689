import { saveAs } from "file-saver";

export const OverviewInterpreterController = [
  "$scope",
  "recordService",
  function ($scope, recordService) {
    function updateAvailableMonths(year) {
      const availableMonths = [];
      const maxMonth = year === now.getFullYear() ? now.getMonth() + 1 : 12;
      $scope.reportInput.month = Math.min(maxMonth, $scope.reportInput.month);

      for (let m = 1; m <= maxMonth; m++) {
        availableMonths.push(m);
      }
      $scope.availableMonths = availableMonths;
    }

    // Librairie Pédagogique

    const now = new Date();
    $scope.startDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));
    $scope.endDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

    $scope.fetchRecords = () => {
      $scope.isLoadingRecords = true;
      const savedRecords = new recordService.RecordList();
      savedRecords.params.bookmarkedOnly = true;
      savedRecords.setStartDate($scope.startDate);
      savedRecords.setEndDate($scope.endDate);
      return savedRecords
        .get()
        .then((records) => ($scope.savedRecords = records))
        .finally(() => ($scope.isLoadingRecords = false));
    };

    $scope.checkAll = () => $scope.savedRecords.forEach((rec) => (rec.isChecked = true));

    $scope.uncheckAll = () => $scope.savedRecords.forEach((rec) => (rec.isChecked = false));

    $scope.downloadCompiledRecords = () => {
      const selectedRecordsIdList = $scope.savedRecords.filter((rec) => rec.isChecked).map((record) => record.recordId);
      if (selectedRecordsIdList.length > 0) {
        $scope.isLoading = true;
        return recordService
          .downloadCompiledRecords(selectedRecordsIdList)
          .then((result) => {
            if (result) {
              const file = new Blob([result], { type: "application/zip" });
              return saveAs(file, "records.zip");
            }
          })
          .finally(() => ($scope.isLoading = false));
      }
    };

    // Rapport d'activité

    $scope.reportInput = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
    };
    const availableYears = [];
    // Fill years array with the last 10 years, including the current year
    for (let y = now.getFullYear(); availableYears.length < 10; y--) {
      availableYears.push(y);
    }

    $scope.availableYears = availableYears;

    $scope.$watch("reportInput.year", (year) => updateAvailableMonths(year));
  },
];
