function ModalInstanceCtrl($scope, $uibModalInstance) {
  $scope.ok = () => $uibModalInstance.close();
  $scope.cancel = () => $uibModalInstance.dismiss("cancel");
}

ModalInstanceCtrl.$inject = ["$scope", "$uibModalInstance"];

export const reallyClickDirective = [
  "$uibModal",
  function ($uibModal) {
    return {
      restrict: "A",
      scope: {
        smsReallyClick: "&",
      },
      link: (scope, element) => {
        element.bind("click", () => {
          const message = element.attr("sms-really-message") || "Êtes-vous sûr·e ?";

          const modalHtml =
            `<div class="modal-body">${message}</div>` +
            `<div class="modal-footer">` +
            `  <button class="btn btn-primary" ng-click="ok()">OK</button>` +
            `  <button class="btn btn-default" ng-click="cancel()">Annuler</button>` +
            `</div>`;

          const uibModalInstance = $uibModal.open({
            template: modalHtml,
            controller: ModalInstanceCtrl,
          });

          return uibModalInstance.result.then(scope.smsReallyClick);
        });
      },
    };
  },
];
