const element = document.createElement("div");
const rowHeight = 30; // Your row height
const headerHeight = 30; // Your header height

export const utilitiesService = [
  function () {
    return {
      decodeHTMLEntities: (str) => {
        if (str && typeof str === "string") {
          // Strip script/html tags
          str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
          str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
          element.innerHTML = str;
          str = element.textContent;
          element.textContent = "";
        }

        return str;
      },

      getTableHeight: (gridOptions) => {
        const height = 20 + gridOptions.data.length * rowHeight + headerHeight;
        return { height: `${Math.min(height, 400)}px` };
      },

      formatId: (id) => id.substring(0, 15),

      getUtcDateTime: (date, hours = 0, minutes = 0) => {
        if (!date) {
          return null;
        }
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hours, minutes));
      },

      getAgeFromBirthdate: (birthdate) => {
        const today = new Date();
        let age = today.getUTCFullYear() - birthdate.getUTCFullYear();
        const m = today.getUTCMonth() - birthdate.getUTCMonth();

        if (m < 0 || (m === 0 && today.getUTCDate() < birthdate.getUTCDate())) {
          age--;
        }

        return age;
      },

      getMonthList: () => [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ],

      dateToString: (date) => (date ? date.toISOString().substring(0, 10) : null),

      dateTimeToString: (date) => (date ? date.toISOString() : null),

      getExtension: (file) => {
        const splitFileName = file.fileName.split(".");
        return splitFileName.length < 2 ? "" : splitFileName[splitFileName.length - 1].toLowerCase();
      },
    };
  },
];
