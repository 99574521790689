export const PatientCreateModalController = [
  "$scope",
  "$uibModalInstance",
  "patientService",
  "toaster",
  function ($scope, $uibModalInstance, patientService, toaster) {
    $scope.patient = patientService.getEmptyPatient();

    $scope.cancel = () => $uibModalInstance.dismiss("cancel");

    $scope.createPatient = () => {
      $scope.isLoading = true;
      return patientService
        .createPatient($scope.patient)
        .then((patient) => {
          toaster.success(`${patient.firstname} ${patient.lastname}`, "Le patient a été créé");
          return $uibModalInstance.close(patient);
        })
        .catch((response) => {
          if (response.status === 409) {
            toaster.warning({
              title: "Ce patient existe déjà",
              body: "Cliquez ici pour l'associer à ce tracé",
              clickHandler: (toast) => {
                toaster.clear(toast);
                return $uibModalInstance.close(response.data);
              },
            });
          }
        })
        .finally(() => ($scope.isLoading = false));
    };
  },
];
