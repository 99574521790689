import template from "./menu.directive.html";

export const menuDirective = [
  "$window",
  "userService",
  function ($window, userService) {
    return {
      restrict: "E",
      template,
      scope: {
        userHomeState: "@userHomeState",
      },
      link: ($scope) => {
        const userInfo = userService.getUserInfo();

        $scope.userSalutation = userInfo.salutation;
        $scope.name = `${userInfo.firstName} ${userInfo.lastName}`;
        $scope.applications = userService.getUserApplications();
        $scope.structureId = userInfo.structureId;
        $scope.applicationsSelectable = $scope.applications.filter(
          (application) => application !== $scope.currentApplication
        );
        $scope.currentApplication = userService.getCurrentApplication();

        $scope.switchApplication = (application) => {
          userService.setCurrentApplication(application);
          $window.location.reload(true);
        };

        $scope.logOut = userService.logOut;
        $scope.onAuthorized = (element) => element.show();
        $scope.onUnauthorized = (element) => element.hide();
      },
    };
  },
];
