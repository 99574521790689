import recordValidationModalTemplate from "./record-validation-modal.html";

export const RecordExecuteController = [
  "$q",
  "$scope",
  "$stateParams",
  "$uibModal",
  "applicationFilterService",
  "recordService",
  "referentielService",
  "toaster",
  "userService",
  "utilitiesService",
  "scrollService",
  function (
    $q,
    $scope,
    $stateParams,
    $uibModal,
    applicationFilterService,
    recordService,
    referentielService,
    toaster,
    userService,
    utilitiesService,
    scrollService
  ) {
    $scope.userInfo = userService.getUserInfo();

    function getInterpretationSoftwareFileChecklist() {
      const selectedSoftware = $scope.referentiels.interpretationSoftwares.find(
        (software) => software.id === $scope.record.remoteInterpretation.interpretationSoftware
      );
      if (!selectedSoftware) {
        return [];
      }

      const uploadedFileFormats = $scope.attachmentFiles.map((file) => utilitiesService.getExtension(file));

      return selectedSoftware.fileCheckList.map((checkListItem) => {
        const uploadedQuantity = uploadedFileFormats.filter((format) => format === checkListItem.format).length;

        checkListItem.missingQuantity = checkListItem.quantity - uploadedQuantity;
        checkListItem.valid = uploadedQuantity === checkListItem.quantity;
        return checkListItem;
      });
    }

    function isActeTypeDeprecated() {
      return $scope.referentiels.acteTypes.every((acteType) => acteType.id !== $scope.record.acteType);
    }

    function fillPathologiesField() {
      if ($scope.record.patho) {
        const selections = $scope.record.patho.split(";");
        $scope.selectedPathologies.items = $scope.referentiels.pathologyList.filter((pathology) =>
          selections.includes(pathology.id)
        );
      }
    }

    function validateRecord() {
      $scope.record.patho = $scope.selectedPathologies.items.map((item) => item.id).join(";");
      $scope.record.recordDate = utilitiesService.dateTimeToString(
        utilitiesService.getUtcDateTime(
          $scope.executionDateField.date,
          $scope.executionDateField.hour,
          $scope.executionDateField.min
        )
      );
      if (!$scope.externalViewerLink) {
        $scope.record.interpretationLink = "";
      } else if (!$scope.record.interpretationLink.startsWith($scope.externalViewerLink)) {
        return toaster.warning(`Les urls de connexion doivent commencer par ${$scope.externalViewerLink}`);
      }
      if ($scope.isUploading.attachments || $scope.isUploading.proofOfAttendance) {
        return toaster.warning("Des documents sont en cours de téléversement");
      }
      return $uibModal
        .open({
          animation: false,
          template: recordValidationModalTemplate,
          controller: "RecordValidationModalController",
          size: "lg",
          resolve: {
            attachmentFiles: () => $scope.attachmentFiles.map((file) => ({ fileName: file.fileName, url: file.url })),
            conclusionFiles: () => [],
            proofOfAttendanceFiles: () =>
              $scope.proofOfAttendanceFiles.map((file) => ({
                fileName: file.fileName,
                url: file.url,
              })),
            record: () => $scope.record,
            dangerMessage: () => {
              const missingFileFormats = $scope.interpretationSoftwareFileChecklist.filter((item) => !item.valid);

              if (!missingFileFormats.length) {
                return null;
              }

              const quantityMissingTotal = missingFileFormats.reduce((total, item) => total + item.missingQuantity, 0);
              const label = `Il semble que le tracé soit incomplet, ${
                quantityMissingTotal > 1
                  ? "les fichiers suivants sont manquants : "
                  : "le fichier suivant est manquant : "
              }`;

              return (
                label +
                missingFileFormats
                  .map(
                    (item) =>
                      `${item.missingQuantity} ${item.format}${
                        item.instructionLabel ? ` (${item.instructionLabel})` : ""
                      }`
                  )
                  .join(", ")
              );
            },
          },
        })
        .result.then(() => {
          $scope.record.status = "Executed";
          return saveRecordOnServer();
        })
        .then(() =>
          toaster.success(
            "Le tracé a bien été envoyé à l'interprétateur",
            "vous pourrez toujours l'éditer jusqu'à la prise en charge par l'interprétateur"
          )
        );
    }

    function saveRecordOnServer() {
      $scope.isLoading = true;
      return recordService
        .save($scope.record)
        .then(() => userService.redirectToUserHome())
        .finally(() => ($scope.isLoading = false));
    }

    function fillExecutionDateField() {
      if (!$scope.record.recordDate) {
        return;
      }
      $scope.executionDateField = {
        date: $scope.record.recordDate,
        hour: $scope.record.recordDate.getUTCHours(),
        min: $scope.record.recordDate.getUTCMinutes(),
      };
    }

    $scope.selectedPathologies = {
      items: [],
    };
    $scope.record = recordService.getEmptyRecord();
    $scope.userId = $scope.userInfo.userId;
    $scope.userType = $scope.userInfo.type;
    $scope.isUploading = { attachments: false, proofOfAttendance: false };

    $scope.getLabel = applicationFilterService.getLabel;
    $scope.isEnabled = applicationFilterService.isEnabled;
    $scope.isTechnician = $scope.userInfo.type === "Nurse";

    $scope.isLoading = true;

    $scope.ATTACHMENTS_SUB_FOLDER = recordService.ATTACHMENTS_SUB_FOLDER;
    $scope.CONSENT_TEXT = recordService.CONSENT_TEXT;
    $scope.RD_CONSENT_TEXT = recordService.RD_CONSENT_TEXT;
    $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER = recordService.PROOF_OF_ATTENDANCE_SUB_FOLDER;

    $scope.executionDateField = { date: null, hour: null, min: null };

    $q.all({
      referentiels: referentielService.get(),
      record: recordService.getRecordInfos($stateParams.id),
      attachmentFiles: recordService.getRecordFiles($stateParams.id, $scope.ATTACHMENTS_SUB_FOLDER),
      proofOfAttendanceFiles: $scope.isEnabled("proofOfAttendance")
        ? recordService.getRecordFiles($stateParams.id, $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER)
        : $q.resolve([]),
    })
      .then((results) => {
        $scope.referentiels = results.referentiels;
        $scope.getServices = referentielService.getServices($scope.referentiels);

        $scope.record = results.record;
        if (isActeTypeDeprecated()) {
          $scope.referentiels.acteTypes.push({ id: $scope.record.acteType, name: $scope.record.acteType });
        }
        fillExecutionDateField();
        fillPathologiesField();

        $scope.attachmentFiles = results.attachmentFiles;
        $scope.proofOfAttendanceFiles = results.proofOfAttendanceFiles;

        $scope.updateInterpretationSoftwareFileChecklist();
      })
      .finally(() => ($scope.isLoading = false));

    $scope.reloadAttachments = () =>
      recordService.getRecordFiles($scope.record.record.recordId, $scope.ATTACHMENTS_SUB_FOLDER).then((files) => {
        $scope.attachmentFiles = files;
        $scope.updateInterpretationSoftwareFileChecklist();
      });

    $scope.reloadProofOfAttendance = () =>
      recordService
        .getRecordFiles($scope.record.record.recordId, $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER)
        .then((files) => ($scope.proofOfAttendanceFiles = files));

    $scope.saveRecord = () => {
      $scope.showErrorsCheckValidity = true;
      $scope.$broadcast("show-errors-check-validity");

      if ($scope.mainForm.$invalid) {
        for (const property in $scope.mainForm) {
          if (Object.prototype.hasOwnProperty.call($scope.mainForm, property) && property[0] !== "$") {
            if ($scope.mainForm[property] && $scope.mainForm[property].$valid === false) {
              scrollService.goTo(`form [name=${property}]`);
              break;
            }
          }
        }
        return;
      }
      return validateRecord();
    };

    $scope.$watch("record.prescriber.service", (prescriberService) => {
      if (prescriberService) {
        referentielService
          .getPrescribers(prescriberService.id)
          .then((prescribers) => ($scope.prescribers = prescribers));
      }
    });

    $scope.isReadOnly = () =>
      // After creation, prescriber can only see the record and add files, but can't modify it.
      $scope.userType === "Doctor"; // 'Doctor' means prescriber… sic

    $scope.cancelAct = () => {
      recordService.cancelAct($scope.record);
      return saveRecordOnServer($scope.record).then(() => toaster.success("Le tracé a bien été annulé"));
    };

    $scope.isUserAllowedToCommentRecord = () => ["Nurse", "NursePlus"].includes($scope.userType);

    $scope.updateInterpretationSoftwareFileChecklist = () => {
      $scope.interpretationSoftwareFileChecklist = getInterpretationSoftwareFileChecklist();
      $scope.externalViewerLink = getInterpretationSoftwareExternalViewerLink();
    };

    function getInterpretationSoftwareExternalViewerLink() {
      const selectedSoftware = $scope.referentiels.interpretationSoftwares.find(
        (software) => software.id === $scope.record.remoteInterpretation.interpretationSoftware
      );
      if (!selectedSoftware) {
        return "";
      }
      return selectedSoftware.externalViewerLink;
    }
  },
];
