let filter;

function clearFilter() {
  filter = {
    firstname: null,
    lastname: null,
    sex: null,
    startDate: null,
    endDate: null,
  };
}

export const PatientSearchController = [
  "$scope",
  "$filter",
  "userService",
  "patientService",
  "$state",
  "$q",
  "toaster",
  function ($scope, $filter, userService, patientService, $state, $q, toaster) {
    const userInfo = userService.getUserInfo();

    function getPatients() {
      $scope.isLoading = true;
      return patientService
        .getPatients(filter)
        .then((result) => ($scope.patientsList = result.foundedPatients))
        .finally(() => ($scope.isLoading = false));
    }

    clearFilter();

    $scope.isInterpretator = () => userInfo.type === "Interpreter";

    $scope.searchByBirthday = () => {
      if ($scope.errorBirthday) {
        toaster.clear($scope.errorBirthday);
        $scope.errorBirthday = null;
      }

      if ($scope.startDate && $scope.endDate && $scope.startDate > $scope.endDate) {
        $scope.errorBirthday = toaster.warning("Les dates de naissance ne sont pas cohérentes");
        return $q.reject(Error("Les dates de naissance ne sont pas cohérentes"));
      }

      filter.startDate = $filter("date")($scope.startDate, "dd/MM/yyyy", "UTC");
      filter.endDate = $filter("date")($scope.endDate, "dd/MM/yyyy", "UTC");
      return getPatients().then(clearFilter);
    };

    $scope.formatPatient = (patient) => {
      if (patient) {
        return `${patient.firstname} ${patient.lastname} | ${$filter("date")(
          patient.birthdate,
          "dd/MM/yyyy",
          "UTC"
        )} | ${patient.ssn}`;
      }
    };

    $scope.getPatient = patientService.getPatientFromAutocomplete;

    $scope.$watch("patientResult", () => {
      if ($scope.patientResult) {
        return $state.go("patient.display", { id: $scope.patientResult.patientId });
      }
    });

    $scope.$watch("noResults", () => {
      if ($scope.noResults) {
        $scope.patientsList = [];
      } else {
        delete $scope.patientsList;
      }
    });
  },
];
