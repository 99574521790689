export const referentielService = [
  "userService",
  "applicationFilterService",
  "$http",
  "utilitiesService",
  function (userService, applicationFilterService, $http, utilitiesService) {
    const userInfo = userService.getUserInfo();
    return {
      getInterpreters: (prescStructureId, intStructureId, patientBirthDate) =>
        $http({
          method: "GET",
          url: "/record/references/interpreter",
          params: {
            prescStructureId,
            intStructureId,
            filterDate: utilitiesService.dateTimeToString(new Date()),
            patientBirthDate: utilitiesService.dateToString(patientBirthDate),
          },
        }).then((response) => applicationFilterService.filterContactsOrStructures(response.data)),

      getTechnicians: (structureId, dateFilter, patientBirthDate) =>
        $http({
          method: "GET",
          url: "/record/references/technician",
          params: {
            structureId,
            filterDate: utilitiesService.dateTimeToString(dateFilter),
            patientBirthDate: utilitiesService.dateToString(patientBirthDate),
          },
        }).then((response) => applicationFilterService.filterContactsOrStructures(response.data)),

      getPrescribers: (serviceId) =>
        $http({
          method: "GET",
          url: "/record/references/prescriber",
          params: {
            serviceId,
          },
        }).then((response) => applicationFilterService.filterContactsOrStructures(response.data)),

      get: (withStructures) => {
        let url = `/record/references/${userInfo.userId}/${userInfo.structureId}`;
        if (withStructures !== false) {
          url += "?withStructures=true";
        }
        return $http.get(url).then((response) => {
          response.data.structures = applicationFilterService.filterContactsOrStructures(response.data.structures);
          response.data.acteTypes = applicationFilterService.filterActTypes(response.data.acteTypes);
          response.data.pathologyList = applicationFilterService.filterPathologies(response.data.pathologyList);
          response.data.interpretationSoftwares = applicationFilterService.filterInterpretationSoftwares(
            response.data.interpretationSoftwares
          );
          return response.data;
        });
      },

      getServices: (referentiel) => (structureId) => {
        if (!structureId) {
          return [];
        }
        const structure = referentiel.structures.find((structure) => structure.id === structureId);
        return structure ? structure.services : [];
      },

      sendFeedback: (type, message, name, email) =>
        $http({
          method: "PUT",
          url: "/feedback",
          data: {
            feedbackType: type,
            message,
            name,
            email,
          },
        }),
    };
  },
];
