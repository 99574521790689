import template from "./footer.directive.html";

export const footerDirective = [
  function () {
    return {
      restrict: "E",
      template,
    };
  },
];
