export const chartJsConfig = [
  "ChartJsProvider",
  function (ChartJsProvider) {
    return ChartJsProvider.setOptions({
      scales: {
        yAxes: [{ gridLines: { display: false }, ticks: { min: 0 } }],
        xAxes: [{ gridLines: { display: false } }],
      },
      tooltips: false,
      plugins: {
        datalabels: {
          backgroundColor: (context) => context.dataset.borderColor,
          borderRadius: 4,
          color: "white",
          font: { weight: "bold" },
        },
      },
    });
  },
];
