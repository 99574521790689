export const PrescriberSearchController = [
  "$scope",
  "userService",
  "prescriberService",
  function ($scope, userService, prescriberService) {
    $scope.isLoading = true;
    prescriberService
      .getPrescribers(userService.getCurrentApplication())
      .then((result) => ($scope.prescribersList = result))
      .finally(() => ($scope.isLoading = false));
  },
];
