import angular from "angular";

function appendTransform(defaults, transform) {
  // We can't guarantee that the default transformation is an array
  defaults = Array.isArray(defaults) ? defaults : [defaults];
  // Append the new transformation to the defaults
  return defaults.concat(transform);
}

function isPatientInvalid(patient) {
  return patient.firstname == null || patient.lastname == null || patient.birthdate == null || patient.sex == null;
}

export const patientService = [
  "userService",
  "utilitiesService",
  "$http",
  "$q",
  "$filter",
  function (userService, utilitiesService, $http, $q, $filter) {
    const userInfo = userService.getUserInfo();

    function fixPatient(patient) {
      patient.firstname = $filter("capitalize")(patient.firstname);
      patient.lastname = $filter("uppercase")(patient.lastname);

      const birthDate = new Date(patient.birthdate);
      patient.birthdate = birthDate;
      patient.birthdateDay = birthDate.getUTCDate();
      patient.birthdateMonth = birthDate.getUTCMonth() + 1;
      patient.birthdateYear = birthDate.getUTCFullYear();

      return patient;
    }

    return {
      getPatientInfos: (patientId) =>
        $http
          .get(`/patient/${utilitiesService.formatId(patientId)}`)
          .then((response) => fixPatient(response.data.foundedPatients[0].patientData)),

      getRecordsFromPatientId: (patientId) =>
        $http
          .get(`/record/list?idPatient=${patientId}`, {
            transformResponse: appendTransform($http.defaults.transformResponse, (data) => data.records),
          })
          .then((response) => response.data),

      createPatient: (patient) => {
        if (isPatientInvalid(patient)) {
          return $q.reject(Error("Invalid patient"));
        }
        const patientCopy = angular.copy(patient);
        return $http({
          method: "POST",
          url: "/patient/create",
          ignoreStatus: 409,
          data: patientCopy,
        })
          .then((response) => fixPatient(response.data))
          .catch((response) => {
            if (response.status === 409) {
              response.data = fixPatient(response.data);
            }
            return $q.reject(response);
          });
      },

      editPatient: (patient) => {
        if (isPatientInvalid(patient)) {
          return $q.reject(Error("Invalid patient"));
        }
        return $http({
          method: "POST",
          url: "/patient/update",
          ignoreStatus: 409,
          data: patient,
        })
          .then((response) => fixPatient(response.data))
          .catch((response) => {
            if (response.status === 409) {
              response.data = fixPatient(response.data);
            }
            return $q.reject(response);
          });
      },

      getEmptyPatient: () => ({
        firstname: null,
        lastname: null,
        birthdate: null,
        sex: null,
        phoneNumber1: "",
        phoneNumber2: "",
        ssn: "",
        measure1: 0.0,
        measure2: 0.0,
        patientCode: "",
        patientId: "",
        structures: [userInfo.structureId],
        address: {
          addressLine1: "",
          addressLine2: "",
          zipCode: "",
          city: "",
          country: "",
        },
      }),

      getImc: (patient) => {
        if (!patient) {
          return;
        }
        if (!patient.measure1 || !patient.measure2) {
          return "inconnu";
        }
        return Math.round(((patient.measure1 / (patient.measure2 * patient.measure2)) * 100) / 100);
      },

      getPatients: (filter) => {
        let chaine = "";

        if (filter.firstname) {
          chaine += `firstname=${filter.firstname}`;
        }
        if (filter.lastname) {
          if (chaine) {
            chaine += "&";
          }
          chaine += `lastname=${filter.lastname}`;
        }
        if (filter.sex) {
          if (chaine) {
            chaine += "&";
          }
          chaine += `sex=${filter.sex}`;
        }
        if (filter.startDate) {
          if (chaine) {
            chaine += "&";
          }
          chaine += `startDate=${filter.startDate}`;
        }
        if (filter.endDate) {
          if (chaine) {
            chaine += "&";
          }
          chaine += `endDate=${filter.endDate}`;
        }
        if (chaine) {
          chaine = `?${chaine}`;
        }
        return $http.get(`/patient/filter${chaine}`).then((response) => response.data);
      },

      getPatientFromAutocomplete: (search) =>
        $http({
          method: "POST",
          url: "/patient/search",
          data: search,
          headers: {
            "Content-Type": "text/plain; charset=utf-8",
          },
        }).then((response) => {
          response.data.forEach(fixPatient);
          return response.data;
        }),
    };
  },
];
