// angular must be imported before any other (and requires jQuery globally imported)
import angular from "angular";

import "@fortawesome/fontawesome-free/css/fontawesome.css"; // eslint-disable-line sort-imports
import "@fortawesome/fontawesome-free/css/solid.css";
import "angular-i18n/fr";
import "angular-ui-grid";
import "angular-ui-grid/ui-grid.css";
import "angularjs-toaster/toaster.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";

// chart.js must be imported before angular-chart.js
import "chart.js";
import "chartjs-plugin-datalabels";
import "angular-chart.js";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "ui-select/dist/select.css";
import { permission, uiPermission } from "angular-permission";

import { $exceptionHandlerFactory } from "./factories/$exception-handler.factory";
import { AdministrationController } from "./controllers/administration/administration.controller";
import { DebugController } from "./controllers/debug/debug.controller";
import { FeedbackController } from "./controllers/feedback/feedback.controller";
import { FooterController } from "./controllers/footer/footer.controller";
import { HomeController } from "./controllers/home/home.controller";
import { HomeInterpreterController } from "./controllers/home/home-interpreter.controller";
import { HomePrescriberController } from "./controllers/home/home-prescriber.controller";
import { HomeTechnicianController } from "./controllers/home/home-technician.controller";
import { LoginCallbackController } from "./controllers/login/login-callback.controller";
import { OverviewInterpreterController } from "./controllers/overview/overview-interpreter.controller";
import { PatientCreateController } from "./controllers/patient/patient-create.controller";
import { PatientCreateModalController } from "./controllers/patient/patient-create-modal.controller";
import { PatientDisplayController } from "./controllers/patient/patient-display.controller";
import { PatientEditController } from "./controllers/patient/patient-edit.controller";
import { PatientSearchController } from "./controllers/patient/patient-search.controller";
import { PrescriberCreateController } from "./controllers/prescriber/prescriber-create.controller";
import { PrescriberDisplayController } from "./controllers/prescriber/prescriber-display.controller";
import { PrescriberSearchController } from "./controllers/prescriber/prescriber-search.controller";
import { RecordCreateController } from "./controllers/record/record-create.controller";
import { RecordDisplayController } from "./controllers/record/record-display.controller";
import { RecordExecuteController } from "./controllers/record/record-execute.controller";
import { RecordInterpretController } from "./controllers/record/record-interpret.controller";
import { RecordSearchController } from "./controllers/record/record-search.controller";
import { RecordValidationModalController } from "./controllers/record/record-validation-modal.controller";
import { StructureCreateController } from "./controllers/structure/structure-create.controller";
import { StructureDisplayController } from "./controllers/structure/structure-display.controller";
import { StructureEditController } from "./controllers/structure/structure-edit.controller";
import { StructureSearchController } from "./controllers/structure/structure-search.controller";
import { WarningModalController } from "./controllers/warning/warning-modal.controller";
import { apiInterceptorFactory } from "./factories/api-interceptor.factory";
import { appRun } from "./app.run";
import { applicationFilterService } from "./services/application-filter.service";
import { autofocusDirective } from "./directives/autofocus.directive";
import { capitalizeFilter } from "./filters/capitalize.filter";
import { chartJsConfig } from "./configs/chartJs.config";
import { compileConfig } from "./configs/compile.config";
import { convertToNumberDirective } from "./directives/convert-to-number.directive";
import { copyToClipboardDirective } from "./directives/copy-to-clipboard.directive";
import { cssVarsConfig } from "./configs/cssVars.config";
import { customTypeaheadDirective } from "./directives/input/custom-typeahead.directive";
import { dateSelectorDirective } from "./directives/input/date-selector.directive";
import { errorInterceptorFactory } from "./factories/error-interceptor.factory";
import { fichePatientDirective } from "./directives/fiche-patient.directive";
import { ficheRecordDirective } from "./directives/fiche-record.directive";
import { footerDirective } from "./directives/footer.directive";
import { interceptorsConfig } from "./configs/interceptors.config";
import { interpretationsService } from "./services/interpretations.service";
import { interpreterSelectorDirective } from "./directives/input/interpreter-selector.directive";
import { listDirective } from "./directives/list/list.directive";
import { listPatientsDirective } from "./directives/list/list-patients.directive";
import { listPrescribersDirective } from "./directives/list/list-prescribers.directive";
import { listRecordsDirective } from "./directives/list/list-records.directive";
import { listStructuresDirective } from "./directives/list/list-structures.directive";
import { loadingFrameDirective } from "./directives/loading-frame.directive";
import { menuDirective } from "./directives/menu/menu.directive";
import { minuteHourSelectorDirective } from "./directives/input/minute-hour-selector.directive";
import { monthReportDirective } from "./directives/report/month-report.directive";
import ngCookies from "angular-cookies";
import ngSanitize from "angular-sanitize";
import { patientService } from "./services/patient.service";
import { prescriberService } from "./services/prescriber.service";
import { rangeFilter } from "./filters/range.filter";
import { reallyClickDirective } from "./directives/really-click.directive";
import { recordService } from "./services/record.service";
import { referentielService } from "./services/referentiel.service";
import { s3FileListDirective } from "./directives/file-storage/s3-file-list.directive";
import { scrollService } from "./services/scroll.service";
import { searchStringFilter } from "./filters/search-string.filter";
import { showErrorsDirective } from "./directives/show-errors.directive";
import { showTabDirective } from "./directives/show-tab.directive";
import { somnoRecordFieldsDirective } from "./directives/somno-record-fields.directive";
import { statesConfig } from "./configs/states.config";
import { structureService } from "./services/structure.service";
import { technicianSelectorDirective } from "./directives/input/technician-selector.directive";
import toaster from "angularjs-toaster";
import uiBoostrap from "angular-ui-bootstrap";
import uiRouter from "@uirouter/angularjs";
import uiSelect from "ui-select";
import { uiSelectPropertyFilter } from "./filters/ui-select-property.filter";
import { uppyUploadDirective } from "./directives/file-storage/uppy-upload.directive";
import { userService } from "./services/user.service";
import { utilitiesService } from "./services/utilities.service";
import { yearReportDirective } from "./directives/report/year-report.directive";

angular
  .module("sms-frontend", [
    "chart.js",
    ngCookies,
    ngSanitize,
    permission,
    toaster,
    uiBoostrap,
    "ui.grid",
    uiPermission,
    uiRouter,
    uiSelect,
  ])

  .config(chartJsConfig)
  .config(compileConfig)
  .config(cssVarsConfig)
  .config(interceptorsConfig)
  .config(statesConfig)

  .controller("AdministrationController", AdministrationController)
  .controller("DebugController", DebugController)
  .controller("FeedbackController", FeedbackController)
  .controller("FooterController", FooterController)
  .controller("HomeController", HomeController)
  .controller("HomeInterpreterController", HomeInterpreterController)
  .controller("HomePrescriberController", HomePrescriberController)
  .controller("HomeTechnicianController", HomeTechnicianController)
  .controller("LoginCallbackController", LoginCallbackController)
  .controller("OverviewInterpreterController", OverviewInterpreterController)
  .controller("PatientCreateController", PatientCreateController)
  .controller("PatientCreateModalController", PatientCreateModalController)
  .controller("PatientDisplayController", PatientDisplayController)
  .controller("PatientEditController", PatientEditController)
  .controller("PatientSearchController", PatientSearchController)
  .controller("PrescriberCreateController", PrescriberCreateController)
  .controller("PrescriberDisplayController", PrescriberDisplayController)
  .controller("PrescriberSearchController", PrescriberSearchController)
  .controller("RecordCreateController", RecordCreateController)
  .controller("RecordDisplayController", RecordDisplayController)
  .controller("RecordExecuteController", RecordExecuteController)
  .controller("RecordInterpretController", RecordInterpretController)
  .controller("RecordSearchController", RecordSearchController)
  .controller("RecordValidationModalController", RecordValidationModalController)
  .controller("StructureCreateController", StructureCreateController)
  .controller("StructureDisplayController", StructureDisplayController)
  .controller("StructureEditController", StructureEditController)
  .controller("StructureSearchController", StructureSearchController)
  .controller("WarningModalController", WarningModalController)

  .directive("smsAutofocus", autofocusDirective)
  .directive("smsConvertToNumber", convertToNumberDirective)
  .directive("smsCopyToClipboard", copyToClipboardDirective)
  .directive("smsCustomTypeahead", customTypeaheadDirective)
  .directive("smsDateSelector", dateSelectorDirective)
  .directive("smsFichePatient", fichePatientDirective)
  .directive("smsFicheRecord", ficheRecordDirective)
  .directive("smsFooter", footerDirective)
  .directive("smsInterpreterSelector", interpreterSelectorDirective)
  .directive("smsList", listDirective)
  .directive("smsListPatients", listPatientsDirective)
  .directive("smsListPrescribers", listPrescribersDirective)
  .directive("smsListRecords", listRecordsDirective)
  .directive("smsListStructures", listStructuresDirective)
  .directive("smsLoadingFrame", loadingFrameDirective)
  .directive("smsMenu", menuDirective)
  .directive("smsMinuteHourSelector", minuteHourSelectorDirective)
  .directive("smsMonthReport", monthReportDirective)
  .directive("smsReallyClick", reallyClickDirective)
  .directive("smsS3FileList", s3FileListDirective)
  .directive("smsShowErrors", showErrorsDirective)
  .directive("smsShowTab", showTabDirective)
  .directive("smsSomnoRecordFields", somnoRecordFieldsDirective)
  .directive("smsTechnicianSelector", technicianSelectorDirective)
  .directive("smsUppyUpload", uppyUploadDirective)
  .directive("smsYearReport", yearReportDirective)

  .factory("$exceptionHandler", $exceptionHandlerFactory)
  .factory("apiInterceptor", apiInterceptorFactory)
  .factory("errorInterceptor", errorInterceptorFactory)

  .filter("capitalize", capitalizeFilter)
  .filter("range", rangeFilter)
  .filter("searchString", searchStringFilter)
  .filter("uiSelectProperty", uiSelectPropertyFilter)

  .service("applicationFilterService", applicationFilterService)
  .service("interpretationsService", interpretationsService)
  .service("patientService", patientService)
  .service("prescriberService", prescriberService)
  .service("recordService", recordService)
  .service("referentielService", referentielService)
  .service("scrollService", scrollService)
  .service("structureService", structureService)
  .service("userService", userService)
  .service("utilitiesService", utilitiesService)

  .run(appRun);
