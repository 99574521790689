export const PatientEditController = [
  "$scope",
  "$stateParams",
  "$state",
  "patientService",
  "toaster",
  function ($scope, $stateParams, $state, patientService, toaster) {
    $scope.isLoading = true;
    patientService
      .getPatientInfos($stateParams.id)
      .then((patient) => ($scope.patient = patient))
      .finally(() => ($scope.isLoading = false));

    $scope.editPatient = () => {
      $scope.isLoading = true;
      return patientService
        .editPatient($scope.patient)
        .then((patient) => {
          toaster.success(`${patient.firstname} ${patient.lastname}`, "Le patient a été mis à jour");
          return $state.go("patient");
        })
        .catch((response) => {
          if (response.status === 409) {
            toaster.warning({
              title: "Ce patient existe déjà",
              body: "Cliquez ici pour accéder à sa fiche",
              clickHandler: (toast) => {
                toaster.clear(toast);
                return $state.go("patient.display", { id: response.data.patientId });
              },
            });
          }
        })
        .finally(() => ($scope.isLoading = false));
    };
  },
];
