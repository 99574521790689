import recordValidationModalTemplate from "./record-validation-modal.html";

export const RecordInterpretController = [
  "$q",
  "$scope",
  "$stateParams",
  "$uibModal",
  "applicationFilterService",
  "interpretationsService",
  "recordService",
  "referentielService",
  "toaster",
  "userService",
  function (
    $q,
    $scope,
    $stateParams,
    $uibModal,
    applicationFilterService,
    interpretationsService,
    recordService,
    referentielService,
    toaster,
    userService
  ) {
    const userInfo = userService.getUserInfo();

    function closeRecordOnServer() {
      $scope.isLoading = true;
      return recordService
        .save($scope.record)
        .then(() => {
          toaster.success("L'interprétation a bien été validée");
          return userService.redirectToUserHome();
        })
        .finally(() => ($scope.isLoading = false));
    }

    function getInterpretation() {
      return interpretationsService
        .getRecordInterpretation($scope.record.record.recordName)
        .then((interpretation) => ($scope.interpretation = interpretation));
    }

    $scope.reloadUploads = () =>
      $q
        .all({
          attachmentFiles: recordService.getRecordFiles($stateParams.id, $scope.ATTACHMENTS_SUB_FOLDER),
          conclusionFiles: recordService.getRecordFiles($stateParams.id, $scope.CONCLUSION_SUB_FOLDER),
        })
        .then((results) => {
          $scope.attachmentFiles = results.attachmentFiles;
          $scope.conclusionFiles = results.conclusionFiles;
        });

    $scope.userType = userInfo.type;
    $scope.userId = userInfo.userId;
    $scope.currentApplication = userService.getCurrentApplication();
    $scope.isUploading = { fromLocal: false, fromRemote: false };

    $scope.isEnabled = applicationFilterService.isEnabled;

    $scope.ATTACHMENTS_SUB_FOLDER = recordService.ATTACHMENTS_SUB_FOLDER;
    $scope.CONCLUSION_SUB_FOLDER = recordService.CONCLUSION_SUB_FOLDER;
    $scope.AUTOMATED_REPORT_SUB_FOLDER = recordService.AUTOMATED_REPORT_SUB_FOLDER;

    $scope.CONSENT_TEXT = recordService.CONSENT_TEXT;
    $scope.RD_CONSENT_TEXT = recordService.RD_CONSENT_TEXT;

    $scope.selectedIndications = {
      items: [],
    };

    $scope.isLoading = true;

    $q.all({
      referentiels: referentielService.get(),
      record: recordService.getRecordInfos($stateParams.id),
      automatedReportFiles: $scope.isEnabled("automatedReport")
        ? recordService.getRecordFiles($stateParams.id, $scope.AUTOMATED_REPORT_SUB_FOLDER)
        : $q.resolve([]),
    })
      .then((results) => {
        $scope.indicationsListArr = results.referentiels.indications;
        $scope.etiologiesListArr = results.referentiels.etiologies;
        $scope.contextsListArr = results.referentiels.indicationContexts;
        $scope.statusArr = results.referentiels.statuses;

        $scope.record = results.record;
        if ($scope.record.patho) {
          $scope.pathoList = $scope.record.patho.split(";");
        }
        if ($scope.record.recordInterpretation.indication) {
          const selections = $scope.record.recordInterpretation.indication.split(";");
          $scope.selectedIndications.items = $scope.indicationsListArr.filter((indication) =>
            selections.includes(indication.id)
          );
        }

        if (results.automatedReportFiles.length) {
          $scope.automatedReportFile = results.automatedReportFiles[0].url;
        }

        return $q.all([getInterpretation(), $scope.reloadUploads()]);
      })
      .finally(() => ($scope.isLoading = false));

    $scope.validateRecord = () => {
      if ($scope.isUploading.fromLocal || $scope.isUploading.fromRemote) {
        return toaster.warning("Votre conclusion est en cours de téléversement");
      }
      $scope.record.status = "Close"; // Before modal open to allow fiche-record to display the conclusion

      return $uibModal
        .open({
          animation: false,
          template: recordValidationModalTemplate,
          controller: "RecordValidationModalController",
          size: "lg",
          resolve: {
            record: () => $scope.record,
            attachmentFiles: () => $scope.attachmentFiles.map((file) => ({ fileName: file.fileName, url: file.url })),
            proofOfAttendanceFiles: () => [],
            conclusionFiles: () => $scope.conclusionFiles.map((file) => ({ fileName: file.fileName, url: file.url })),
            dangerMessage: () => null,
          },
        })
        .result.then(closeRecordOnServer);
    };

    $scope.connectAccount = () =>
      `/interpretations/accounts/${$scope.record.record.recordName}?X-Bios-Token=${userService.getAccessToken()}`;

    $scope.uploadConclusionFromRemoteDesktop = () => {
      $scope.isUploading.fromRemote = true;
      return interpretationsService
        .uploadConclusionFromRemoteDesktop($scope.record.record.recordName)
        .then(() => {
          $scope.reloadUploads();
          toaster.success(
            "Les conclusions de l'interprétation ont bien été téléchargées",
            "Vous pouvez maintenant valider l'interprétation"
          );
        })
        .catch((error) => {
          if (error.data === "CONCLUSION_NOT_FOUND") {
            toaster.warning("Aucune conclusion n'a été trouvée sur le bureau à distance");
          } else {
            return $q.reject(error);
          }
        })
        .finally(() => ($scope.isUploading.fromRemote = false));
    };

    $scope.getUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
];
