export const prescriberService = [
  "$http",
  function ($http) {
    return {
      getPrescribers: (application) => $http.get(`/prescriber/list/${application}`).then((response) => response.data),

      getPrescriberInfo: (id) => $http.get(`/prescriber/get/${id}`).then((response) => response.data),

      createPrescriber: (prescriber) => $http.post("/prescriber/create", prescriber),
    };
  },
];
