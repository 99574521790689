export const HomeTechnicianController = [
  "userService",
  "$scope",
  "applicationFilterService",
  "recordService",
  function (userService, $scope, applicationFilterService, recordService) {
    $scope.getLabel = applicationFilterService.getLabel;
    $scope.isEnabled = applicationFilterService.isEnabled;

    $scope.tab = 1;

    $scope.userInfo = userService.getUserInfo();
    const technicianId = $scope.userInfo.contactId.slice(0, -3);

    $scope.isLoadingNewDemands = true;
    const newDemands = new recordService.RecordList();
    newDemands.params.status = "Created";
    newDemands.params.technicianId = technicianId;
    newDemands
      .get()
      .then((records) => ($scope.newDemandList = records))
      .finally(() => ($scope.isLoadingNewDemands = false));

    $scope.isLoadingWaitingInterpretationRecords = true;
    const waitingForInterpretationRecordsRequest = new recordService.RecordList();
    waitingForInterpretationRecordsRequest.params.status = "Executed";
    waitingForInterpretationRecordsRequest.params.technicianId = technicianId;
    waitingForInterpretationRecordsRequest
      .get()
      .then((records) => ($scope.waitingForInterpretationRecordList = records))
      .finally(() => ($scope.isLoadingWaitingInterpretationRecords = false));

    if ($scope.isEnabled("proofOfAttendance")) {
      $scope.isLoadingAwaitingForProofOfAttendanceRecordList = true;
      const awaitingForProofOfAttendanceRecordList = new recordService.RecordList();
      awaitingForProofOfAttendanceRecordList.params.technicianId = technicianId;
      awaitingForProofOfAttendanceRecordList.params.status = "Executed,Close";
      awaitingForProofOfAttendanceRecordList.params.awaitingProofOfAttendanceOnly = true;
      // Retrieve only the records since the proof of attendance feature was enabled (SMS-908)
      awaitingForProofOfAttendanceRecordList.params.startDate = "2020-02-19";
      awaitingForProofOfAttendanceRecordList
        .get()
        .then((records) => ($scope.awaitingForProofOfAttendanceRecordList = records))
        .finally(() => ($scope.isLoadingAwaitingForProofOfAttendanceRecordList = false));
    }
  },
];
