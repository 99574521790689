export const PrescriberCreateController = [
  "$scope",
  "$state",
  "userService",
  "referentielService",
  "prescriberService",
  "toaster",
  "scrollService",
  function ($scope, $state, userService, referentielService, prescriberService, toaster, scrollService) {
    const userInfo = userService.getUserInfo();

    $scope.prescriber = {};
    $scope.address = {};
    $scope.validationEnabled = true;
    $scope.salutations = ["Dr.", "Pr."];
    $scope.structureId = userInfo.structureId;

    function createPrescriber() {
      $scope.isLoading = true;
      $scope.prescriber.application = userService.getCurrentApplication();
      $scope.prescriber.serviceId = $scope.service.id;
      $scope.prescriber.accountId = $scope.account.id;
      $scope.prescriber.salutation = $scope.salutation.name;
      if ($scope.address.postalCode) {
        $scope.prescriber.address.postalCode = $scope.address.postalCode.toString();
      }

      return prescriberService
        .createPrescriber($scope.prescriber)
        .then(() => {
          toaster.success(`${$scope.prescriber.firstName} ${$scope.prescriber.lastName}`, "Prescripteur créé");
          return $state.go("prescriber");
        })
        .finally(() => ($scope.isLoading = false));
    }

    function isValid() {
      $scope.showErrorsCheckValidity = true;
      $scope.$broadcast("show-errors-check-validity");

      if ($scope.mainForm.$invalid) {
        for (const property in $scope.mainForm) {
          if (Object.prototype.hasOwnProperty.call($scope.mainForm, property) && property[0] !== "$") {
            if ($scope.mainForm[property].$valid === false) {
              scrollService.goTo(`form [name=${property}]`);
              break;
            }
          }
        }
        return false;
      }
      return true;
    }

    $scope.cannotAdministratePrescriberFromOtherStructures = userInfo.type !== "NursePlus";

    referentielService.get().then((result) => {
      $scope.referentiels = result;
      $scope.structures = $scope.referentiels.structures;
      $scope.getServices = referentielService.getServices($scope.referentiels);
      $scope.account = $scope.referentiels.structures.find((structure) => structure.id === userInfo.structureId);
    });

    $scope.submitPrescriber = () => {
      if (isValid()) {
        createPrescriber();
      }
    };
  },
];
