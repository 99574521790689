import angular from "angular";

export const showTabDirective = [
  function () {
    return {
      link: (_$scope, element) => {
        element.click((e) => {
          e.preventDefault();
          angular.element(element).tab("show");
        });
      },
    };
  },
];
