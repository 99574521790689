export const convertToNumberDirective = [
  function () {
    return {
      require: "ngModel",
      link: (_$scope, _element, _attrs, ngModel) => {
        ngModel.$parsers.push((val) => (val ? parseInt(val, 10) : null));
        ngModel.$formatters.push((val) => (val ? `${val}` : null));
      },
    };
  },
];
