import angular from "angular";

function encodeQueryData(data) {
  const ret = [];
  for (const d in data) {
    if (Object.prototype.hasOwnProperty.call(data, d) && data[d] !== "") {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  }
  return ret.join("&");
}

export const recordService = [
  "userService",
  "$filter",
  "$http",
  "$state",
  "$window",
  "patientService",
  "utilitiesService",
  function (userService, $filter, $http, $state, $window, patientService, utilitiesService) {
    class RecordList {
      constructor() {
        this.params = {
          appId: "",
          awaitingProofOfAttendanceOnly: "",
          bookmarkedOnly: "",
          diagDoctorId: "",
          diagDoctorType: "",
          diagStructureId: "",
          endDate: "",
          idPatient: "",
          indications: "",
          name: "",
          notes: "",
          prescriberId: "",
          prescriberStructureId: "",
          startDate: "",
          status: "", // Comma separated status list
          technicianId: "",
          technicianStructureId: "",
        };
      }

      setStartDate(utcDate) {
        this.params.startDate = utilitiesService.dateToString(utcDate) || "";
      }

      setEndDate(utcDate) {
        this.params.endDate = utilitiesService.dateToString(utcDate) || "";
      }

      get() {
        this.params.appId = userService.getCurrentApplication();
        const url = `/record/list?${encodeQueryData(this.params)}`;
        return $http.get(url).then((response) =>
          response.data.map((record) => {
            record.patientName = $filter("capitalize")(record.patientName);
            record.patientFirstName = $filter("uppercase")(record.patientFirstName);
            record.recordDate = new Date(record.recordDate);
            return record;
          })
        );
      }
    }

    return {
      ATTACHMENTS_SUB_FOLDER: "attachments",
      CONCLUSION_SUB_FOLDER: "conclusion",
      CONCLUSION_REVIEW_SUB_FOLDER: "conclusion-review",
      AUTOMATED_REPORT_SUB_FOLDER: "automated-report",
      PROOF_OF_ATTENDANCE_SUB_FOLDER: "proof-of-attendance",

      CONSENT_TEXT:
        "Le consentement libre et éclairé du patient ou, dans le cas où le patient n'est pas en " +
        "mesure de délivrer un tel consentement (mineurs, adultes protégés, incapable de donner son consentement)," +
        " le consentement de son représentant légal, a été recueilli conformément à la législation locale applicable." +
        " Le patient a expressément donné son accord pour bénéficier du service de télétransmission mis en place " +
        "par Bioserenity et pour le traitement des données personnelles nécessaires au déroulement de la solution susmentionnée.",

      RD_CONSENT_TEXT:
        "Le patient accepte que ses données (pseudonymisées, non directement identifiantes) " +
        "soient utilisées à des fins de R&D afin d’améliorer l'offre de produits et services de Bioserenity.",

      getStatusLabel: (record) =>
        ({
          Close: "Fermé",
          Closed: "Annulé",
          Created: "Créé",
          Draft: "Brouillon",
          Executed: "Exécuté",
        }[record.status]),

      getRecordInfos: (recordId) =>
        $http.get(`/record/get/${recordId}`).then((response) => {
          const record = response.data;

          record.recordInterpretation.analysis = utilitiesService.decodeHTMLEntities(
            record.recordInterpretation.analysis
          );
          record.recordInterpretation.conclusion = utilitiesService.decodeHTMLEntities(
            record.recordInterpretation.conclusion
          );
          record.commentary = utilitiesService.decodeHTMLEntities(record.commentary);
          record.prescription = utilitiesService.decodeHTMLEntities(record.prescription);
          record.clinical_summary = utilitiesService.decodeHTMLEntities(record.clinical_summary);
          record.recordDate = new Date(record.recordDate);

          if (!record.interpreter.structure.id) {
            record.interpreter.structure = null;
          }
          if (!record.interpreter.contact.id) {
            record.interpreter.contact = null;
          }
          if (!record.technician.structure.id) {
            record.technician.structure = null;
          }
          if (!record.technician.contact.id) {
            record.technician.contact = null;
          }
          if (!record.technician.service.id) {
            record.technician.service = null;
          }
          if (!record.prescriber.structure.id) {
            record.prescriber.structure = null;
          }
          if (!record.prescriber.contact.id) {
            record.prescriber.contact = null;
          }
          if (!record.prescriber.service.id) {
            record.prescriber.service = null;
          }

          const patientId =
            record.patient.patientId.length === 15
              ? record.patient.patientId.patientId
              : record.patient.patientId.slice(0, record.patient.patientId.length - 3); // WTF?!
          return patientService.getPatientInfos(patientId).then((patientInfos) => {
            record.patientInfos = patientInfos;
            return record;
          });
        }),

      getInterpreterMonthReport: (month, year) =>
        $http.get(`/record/report/interpreter/month/${month}/${year}`).then((response) => response.data),

      getInterpreterYearReport: (year) =>
        $http.get(`/record/report/interpreter/year/${year}`).then((response) => response.data),

      alterBookmark: (recordId) =>
        $http({
          method: "POST",
          url: "/record/bookmark",
          data: recordId,
          headers: {
            "Content-Type": "text/plain",
          },
        }),

      downloadCompiledRecords: (recordIds) =>
        $http({
          method: "POST",
          url: "/record/reports",
          data: recordIds,
          responseType: "arraybuffer",
        }).then((response) => response.data),

      getRecordPdf: (recordId) =>
        $http.get(`/record/report/${recordId}`, { responseType: "arraybuffer" }).then((response) => response.data),

      getRecordFiles: (recordId, subFolderName) => {
        const url = `/record/aws/urls/${recordId}${subFolderName ? `/${subFolderName}` : ""}`;
        return $http
          .get(url, {
            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
            },
          })
          .then((response) => response.data.files);
      },

      RecordList,

      getEmptyRecord: () => ({
        status: null,
        record: {
          recordName: "",
          recordId: "",
        },
        acteType: "",
        appId: userService.getCurrentApplication(),
        commentary: "",
        contact: {
          contact_fax: false,
          contact_mail: false,
          contact_sms: false,
        },
        recordDate: null,
        executedAt: null,
        closedAt: null,
        patho: "",
        patient: {
          birthdate: null,
          patientName: "",
          patientSexe: "",
          patientId: "",
        },
        place: "",
        prescription: "",
        visualisation: "",
        note: "",
        ipp: "",
        clinical_summary: "",
        recordInterpretation: {
          analysis: "",
          conclusion: "",
          indication: "",
        },
        technician: {
          contact: null,
          structure: null,
        },
        prescriber: {
          contact: null,
          structure: null,
          service: null,
        },
        interpreter: {
          contact: null,
          structure: null,
        },
        interpretationLink: "",
        isUrgent: false,
        isConsent: false,
        isRdConsent: true, // Should be false: https://www.cnil.fr/fr/conformite-rgpd-comment-recueillir-le-consentement-des-personnes
        containsVideoFile: false,
        somnoDetail: {
          awakeningNumber: 0,
          isAwakeningNumberUnknown: false,
          cpap: false,
          wakeUpTime: {
            hour: 0,
            minute: 0,
          },
          bedTime: {
            hour: 0,
            minute: 0,
          },
        },
        remoteInterpretation: {
          interpretationSoftware: "",
        },
      }),

      deleteDocument: (recordId, filename) =>
        $http({
          method: "DELETE",
          url: `/record/${recordId}/file/${filename}`,
        }),

      deleteDocumentAsJson: (recordId, fileName) =>
        $http({
          url: "/record/file",
          method: "DELETE",
          data: {
            recordId,
            fileName,
          },
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }),

      redirectToExecute: (id) => {
        $window.scrollTo(0, 0);
        return $state.go("record.execute", { id });
      },

      save: (record) =>
        $http({
          method: "POST",
          url: record.status === "Created" ? "/record/save/create" : "/record/save/update",
          headers: {
            "Content-Type": undefined,
            __XHR__: () => {},
          },
          transformRequest: (data) => {
            const formData = new FormData();
            formData.append("recordData", angular.toJson(data.recordData));

            return formData;
          },
          data: {
            recordData: record,
          },
        }).then((response) => response.data.salesForceRecordId),

      cancelAct: (record) => {
        record.acteType = "Annulé";
        record.status = "Closed";
      },
    };
  },
];
