import template from "./s3-file-list.directive.html";

export const s3FileListDirective = [
  "recordService",
  "utilitiesService",
  "$q",
  function (recordService, utilitiesService, $q) {
    return {
      restrict: "E",
      template,
      scope: {
        recordId: "@recordId",
        recordFiles: "=recordFiles",
        subFolder: "@",
        isRemovable: "=isRemovable",
        onFileRemoved: "&",
      },
      link: ($scope) => {
        function getFilePath(file) {
          return $scope.subFolder ? `${$scope.subFolder}/${file.fileName}` : file.fileName;
        }

        $scope.AUTO_HIDE_FILES_LENGTH = 4;

        $scope.isPdf = (file) => utilitiesService.getExtension(file) === "pdf";

        $scope.isWord = (file) => ["doc", "docx", "rtf"].includes(utilitiesService.getExtension(file));

        $scope.isArchive = (file) => utilitiesService.getExtension(file) === "zip";

        $scope.deleteExistingFile = (file) => {
          $scope.recordFiles.splice($scope.recordFiles.indexOf(file), 1);
          $scope.onFileRemoved();
          return recordService.deleteDocumentAsJson($scope.recordId, getFilePath(file));
        };

        $scope.deleteAllFiles = () =>
          $q
            .all(
              $scope.recordFiles.map((file) => recordService.deleteDocumentAsJson($scope.recordId, getFilePath(file)))
            )
            .then(() => ($scope.recordFiles.length = 0));
      },
    };
  },
];
