export const interpretationsService = [
  "$http",
  "userService",
  function ($http, userService) {
    const currentApplication = userService.getCurrentApplication();
    return {
      canCreateAccount: (record) => record.isEligibleForPrepare && !record.hasWindowsAccount,

      canRecreateAccount: (record) => record.isEligibleForPrepare && record.hasWindowsAccount,

      canConnectAccount: (record) => record.isEligibleForStart && record.isWindowsAccountEnabled,

      canReEnableAccount: (record) =>
        record.isEligibleForReEnable && record.hasWindowsAccount && !record.isWindowsAccountEnabled,

      canRemoveAccount: (record) => record.hasWindowsAccount,

      connectAccount: (record) =>
        `/interpretations/accounts/${record.name}?X-Bios-Token=${userService.getAccessToken()}`,

      getAllInterpretations: () =>
        $http.get(`/interpretations/records/list/${currentApplication}`).then((response) => response.data),

      createAccount: (record) => $http.put(`/interpretations/accounts/${record.name}`),

      reEnableAccount: (record) =>
        $http.patch(`/interpretations/accounts/${record.name}`).then((response) => response.data),

      removeAccount: (record) => $http.delete(`/interpretations/accounts/${record.name}`),

      removeObsoleteAccounts: () => $http.delete(`/interpretations/accounts/list/${currentApplication}`),

      getRecordInterpretation: (recordName) =>
        $http({
          method: "GET",
          url: `/interpretations/records/${recordName}`,
          ignoreStatus: 404,
        }).then((response) => response.data),

      uploadConclusionFromRemoteDesktop: (recordName) =>
        $http({
          method: "PUT",
          url: `/interpretations/accounts/${recordName}/conclusion`,
          ignoreStatus: 400,
        }),
    };
  },
];
