import template from "./custom-typeahead.directive.html";

export const customTypeaheadDirective = [
  function () {
    return {
      restrict: "E",
      scope: {
        model: "=",
        items: "&",
        customPlaceholder: "@",
        isRequired: "@",
        isDisabled: "=",
      },
      template,
      link: ($scope, element) => {
        function isModelNotInItems(items = []) {
          return items.every((item) => $scope.model.id !== item.id);
        }

        // Workaround for bug with typeahead-min-length="0", which is triggered only after the first item selection
        $scope.model = "";
        delete $scope.model;

        $scope.getLabel = (item) => (item ? item.name + (item.fax ? ` (${item.fax})` : "") : "");

        $scope.$watchCollection("items()", (items = []) => {
          $scope.placeholder = items.length === 0 ? "Aucun élément" : $scope.customPlaceholder;
          if ($scope.model && isModelNotInItems(items)) {
            $scope.clear();
          }
        });

        // Fix for typehead component in Firefox, which does not trigger the input keyup event read by sms-show-error directive
        $scope.onItemSelection = () => {
          element.parent().removeClass("has-error");
          element.children().children().children().blur();
        };

        $scope.isItemSelected = () => $scope.isDisabled || $scope.model;

        $scope.clear = () => delete $scope.model;
      },
    };
  },
];
