export const searchStringFilter = [
  function () {
    return (items, searchText) => {
      if (!searchText) {
        return items;
      }
      return items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
    };
  },
];
