import angular from "angular";

export const StructureEditController = [
  "$scope",
  "$stateParams",
  "$state",
  "structureService",
  "toaster",
  "scrollService",
  "referentielService",
  function ($scope, $stateParams, $state, structureService, toaster, scrollService, referentielService) {
    function isStructureValid() {
      $scope.$broadcast("show-errors-check-validity");
      if ($scope.mainForm.$invalid) {
        for (const property in $scope.mainForm) {
          if (
            Object.prototype.hasOwnProperty.call($scope.mainForm, property) &&
            property[0] !== "$" &&
            property !== "service"
          ) {
            if ($scope.mainForm[property].$valid === false) {
              scrollService.goTo(`form [name=${property}]`);
              return false;
            }
          }
        }
      }
      return true;
    }

    $scope.structure = {};
    $scope.structureId = $stateParams.id;
    $scope.structure.services = [];
    $scope.newService = { name: "", phone: "", fax: "" };
    $scope.isLoading = true;

    $scope.timezones = [];
    referentielService.get(false).then((response) => {
      $scope.timezones = response.timezones;
    });

    structureService
      .getStructureInfo($scope.structureId)
      .then((structure) => ($scope.structure = structure))
      .finally(() => ($scope.isLoading = false));

    $scope.submitStructure = () => {
      $scope.showErrorsCheckValidity = true;
      if (isStructureValid()) {
        $scope.isLoading = true;
        return structureService
          .editStructure($scope.structure)
          .then(() => {
            toaster.success($scope.structure.name, "La structure a été mise à jour");
            return $state.go("structure");
          })
          .finally(() => ($scope.isLoading = false));
      }
    };

    $scope.addService = (newService) => {
      if (structureService.validateService(newService)) {
        $scope.structure.services.push(angular.copy(newService));
        delete newService.name;
        delete newService.phone;
        delete newService.fax;
      }
    };

    $scope.deleteService = (index) => $scope.structure.services.splice(index, 1);
  },
];
