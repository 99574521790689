export const FeedbackController = [
  "$scope",
  "referentielService",
  "toaster",
  function ($scope, referentielService, toaster) {
    function resetToDefault() {
      $scope.message = "";
      $scope.type = "Feature";
    }

    resetToDefault();

    $scope.feedbackTypes = [
      { name: "Fonctionnalité", value: "Feature" },
      { name: "Bug", value: "Bug" },
    ];

    $scope.send = () => {
      $scope.isLoading = true;
      return referentielService
        .sendFeedback($scope.type, $scope.message)
        .then(() => {
          toaster.success("Le message a bien été envoyé");
          resetToDefault();
        })
        .finally(() => ($scope.isLoading = false));
    };
  },
];
