import angular from "angular";

export const RecordSearchController = [
  "$scope",
  "userService",
  "recordService",
  "referentielService",
  "toaster",
  "utilitiesService",
  function ($scope, userService, recordService, referentielService, toaster, utilitiesService) {
    const userInfo = userService.getUserInfo();

    $scope.range = (min, max, step = 1) => {
      const input = [];
      for (let i = min; i <= max; i += step) {
        input.push(i);
      }
      return input;
    };

    const filtre = {
      status: "",
      age: "",
      indications: "",
      notes: "",
    };

    $scope.filtre = angular.copy(filtre);

    $scope.reset = () => ($scope.filtre = angular.copy(filtre));

    $scope.months = utilitiesService.getMonthList();

    $scope.max = 10;
    $scope.pageAc = 1;
    $scope.init = 0;
    $scope.ordre = "-recordDate";

    referentielService.get(false).then((referentiel) => ($scope.referentiel = referentiel));

    $scope.removeUploadInProgress = (status) => !(status.id === "Upload in progress");

    $scope.dataListRec = [];
    $scope.search = () => {
      $scope.isLoading = true;
      $scope.records = null;
      const recordList = new recordService.RecordList();
      recordList.params.status = $scope.filtre.status || "";
      recordList.params.indications = $scope.filtre.indications || "";
      recordList.params.etiology = $scope.filtre.etiology || "";
      recordList.params.indicationContext = $scope.filtre.indicationContext || "";
      recordList.params.notes = $scope.filtre.notes;
      recordList.params.age = $scope.filtre.age;
      recordList.params.name = $scope.filtre.name || "";

      const structureId = userInfo.structureId.slice(0, -3);
      if (userInfo.type === "Doctor") {
        recordList.params.prescriberStructureId = structureId;
      } else if (userInfo.type === "Interpreter") {
        recordList.params.diagStructureId = structureId;
      } else if (["Nurse", "NursePlus"].includes(userInfo.type)) {
        recordList.params.technicianStructureId = structureId;
      }

      return recordList
        .get()
        .then((records) => {
          $scope.records = records;
          if ($scope.records.length >= 500) {
            toaster.info(
              "Seuls les 500 derniers tracés exécutés sont affichés",
              "Ajoutez des paramètres de recherche pour trouver des tracés antérieurs"
            );
          }
        })
        .finally(() => ($scope.isLoading = false));
    };
  },
];
