export const StructureDisplayController = [
  "$scope",
  "$stateParams",
  "structureService",
  function ($scope, $stateParams, structureService) {
    $scope.id = $stateParams.id;
    $scope.isLoading = true;
    structureService
      .getStructureInfo($scope.id)
      .then((structure) => ($scope.structure = structure))
      .finally(() => ($scope.isLoading = false));
  },
];
