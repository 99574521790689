import template from "./list-prescribers.directive.html";

const cellTemplate =
  '<div class="ui-grid-vcenter">' +
  '  <a ui-sref="prescriber.display({id: row.entity.id})">' +
  '    <button type="button" class="btn btn-xs btn-default" title="Visualiser">' +
  '      <span class="glyphicon glyphicon-eye-open"></span>' +
  "    </button>" +
  "  </a>" +
  "</div>";

export const listPrescribersDirective = [
  function () {
    return {
      restrict: "E",
      scope: {
        list: "=",
        listTitle: "@",
        isLoading: "=",
      },
      template,
      link: ($scope) => {
        $scope.columnDefs = [
          { name: "firstName", displayName: "Prénom", suppressRemoveSort: true },
          { name: "lastName", displayName: "Nom", suppressRemoveSort: true },
          { name: "accountName", displayName: "Structure", suppressRemoveSort: true },
          {
            enableSorting: false,
            name: "Édition",
            displayName: "",
            width: 40,
            cellTemplate,
          },
        ];
      },
    };
  },
];
