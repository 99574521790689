import angular from "angular";

export const StructureCreateController = [
  "$scope",
  "$state",
  "structureService",
  "toaster",
  "scrollService",
  "referentielService",
  function ($scope, $state, structureService, toaster, scrollService, referentielService) {
    function isStructureValid() {
      $scope.$broadcast("show-errors-check-validity");
      if ($scope.mainForm.$invalid) {
        for (const property in $scope.mainForm) {
          if (
            Object.prototype.hasOwnProperty.call($scope.mainForm, property) &&
            property[0] !== "$" &&
            property !== "service"
          ) {
            if ($scope.mainForm[property].$valid === false) {
              scrollService.goTo(`form [name=${property}]`);
              return false;
            }
          }
        }
      }

      if ($scope.structure.services.length === 0) {
        toaster.warning("La structure n'a pas pu être créée", "Veuillez ajouter au moins un service");
        return false;
      }

      return true;
    }

    $scope.structure = {};
    $scope.structure.services = [];
    $scope.newService = { name: "", phone: "", fax: "" };

    $scope.structure.timezone = "Europe/Paris"; // Most likely time zone
    $scope.timezones = [];
    referentielService.get(false).then((response) => {
      $scope.timezones = response.timezones;
    });

    $scope.submitStructure = () => {
      $scope.showErrorsCheckValidity = true;
      if (isStructureValid()) {
        $scope.isLoading = true;
        return structureService
          .createStructure($scope.structure)
          .then(() => {
            toaster.success($scope.structure.name, "La structure a été créée");
            return $state.go("structure");
          })
          .finally(() => ($scope.isLoading = false));
      }
    };

    $scope.addService = (newService) => {
      if (structureService.validateService(newService)) {
        $scope.structure.services.push(angular.copy(newService));
        delete newService.name;
        delete newService.phone;
        delete newService.fax;
      }
    };

    $scope.deleteService = (index) => $scope.structure.services.splice(index, 1);
  },
];
