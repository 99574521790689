import template from "./list.directive.html";

const headerHeight = 36;
const rowHeight = 40;
const maxRows = 10;

export const listDirective = [
  function () {
    return {
      restrict: "E",
      scope: {
        list: "=",
        listTitle: "=",
        columnDefs: "=",
        isLoading: "=",
      },
      template,
      link: ($scope) => {
        function calculateGridHeight() {
          if (!$scope.gridOptions.data || $scope.gridOptions.data.length === 0) {
            $scope.gridHeight = "20px";
            return;
          }
          const nbRowsDisplayed = Math.min($scope.gridOptions.data.length, maxRows);
          $scope.gridHeight = `${headerHeight + nbRowsDisplayed * rowHeight}px`;
        }

        $scope.gridOptions = {
          enableSorting: true,
          data: $scope.list,
          enableColumnMenus: false,
          rowTemplate:
            "<div ng-class=\"{'bg-danger' : row.entity.isUrgent}\">" +
            '  <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ui-grid-cell>' +
            "  </div>" +
            "</div>",
          autoResize: true,
          rowHeight: 40,
          columnDefs: $scope.columnDefs,
          onRegisterApi: (gridApi) =>
            gridApi.core.on.sortChanged($scope, (_grid, sortColumns) => {
              while (sortColumns.length > 1) {
                sortColumns[0].unsort();
                sortColumns.shift();
              }
            }),
        };

        calculateGridHeight();

        $scope.$watch("list", () => {
          if ($scope.isLoading) {
            return;
          }
          if (!$scope.list) {
            return;
          }

          $scope.gridOptions.data = $scope.list;
          $scope.gridOptions.columnDefs = $scope.columnDefs;
          calculateGridHeight();
        });
      },
    };
  },
];
