import angular from "angular";

export const scrollService = [
  function () {
    return {
      goTo: (selector) =>
        angular.element("html, body").animate({ scrollTop: angular.element(selector).offset().top - 100 }, "fast"),
    };
  },
];
