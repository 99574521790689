import template from "./list-structures.directive.html";

const cellTemplate =
  '<div class="ui-grid-vcenter">' +
  '  <a ui-sref="structure.display({id: row.entity.id})">' +
  '    <button type="button" class="btn btn-xs btn-default" title="Visualiser">' +
  '      <span class="glyphicon glyphicon-eye-open"></span>' +
  "    </button>" +
  "  </a>" +
  '  <a ui-sref="structure.edit({id: row.entity.id})">' +
  '    <button type="button" class="btn btn-xs btn-default" title="Éditer">' +
  '      <span class="glyphicon glyphicon-pencil"></span>' +
  "    </button>" +
  "  </a>" +
  "</div>";

export const listStructuresDirective = [
  function () {
    return {
      restrict: "E",
      scope: {
        list: "=",
        listTitle: "@",
        isLoading: "=",
      },
      template,
      link: ($scope) => {
        $scope.columnDefs = [
          { name: "name", displayName: "Nom", suppressRemoveSort: true },
          { name: "description", displayName: "Description", suppressRemoveSort: true },
          { name: "phone", displayName: "Téléphone", suppressRemoveSort: true },
          { name: "services.length", displayName: "Nombre de services", suppressRemoveSort: true },
          {
            enableSorting: false,
            name: "Édition",
            displayName: "",
            width: 80,
            cellTemplate,
          },
        ];
      },
    };
  },
];
