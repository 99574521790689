import template from "./list-patients.directive.html";

function dateSortFn(a, b) {
  if (a === "Invalid Date") {
    return -1;
  }
  if (b === "Invalid Date") {
    return 1;
  }
  if (a === b) {
    return 0;
  }
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
}

export const listPatientsDirective = [
  "userService",
  function (userService) {
    return {
      restrict: "E",
      scope: {
        list: "=",
        listTitle: "@",
        isLoading: "=",
      },
      template,
      link: ($scope) => {
        const userInfo = userService.getUserInfo();

        let template =
          '<div class="ui-grid-vcenter">' +
          '  <a ui-sref="patient.display({id: row.entity.patientId})">' +
          '    <button type="button" class="btn btn-xs btn-default" title="Visualiser">' +
          '      <span class="glyphicon glyphicon-eye-open"></span>' +
          "    </button>" +
          "  </a>";

        let width = 40;

        if (userInfo.type !== "Interpreter") {
          template +=
            '  <a ui-sref="patient.edit({id: row.entity.patientId})">' +
            '    <button type="button" class="btn btn-xs btn-default" title="Éditer">' +
            '      <span class="glyphicon glyphicon-pencil"></span>' +
            "    </button>" +
            "  </a>";

          width = 80;
        }

        template += "</div>";

        $scope.columnDefs = [
          { name: "patientData.firstname", displayName: "Prénom", suppressRemoveSort: true },
          { name: "patientData.lastname", displayName: "Nom", suppressRemoveSort: true },
          {
            name: "patientData.birthdate",
            displayName: "Date de naissance",
            type: "date",
            cellFilter: "date:'dd/MM/yyyy':'UTC'",
            suppressRemoveSort: true,
            sortingAlgorithm: dateSortFn,
          },
          {
            enableSorting: false,
            name: "Édition",
            displayName: "",
            width,
            cellTemplate: template,
          },
        ];
      },
    };
  },
];
