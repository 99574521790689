import template from "./year-report.directive.html";

export const yearReportDirective = [
  "recordService",
  "utilitiesService",
  function (recordService, utilitiesService) {
    return {
      restrict: "E",
      scope: {
        year: "=",
      },
      template,
      link: ($scope) => {
        $scope.monthsOfYear = utilitiesService.getMonthList();

        $scope.$watch("year", () => $scope.getYearReport());

        $scope.getYearReport = () => {
          $scope.isLoading = true;
          return recordService
            .getInterpreterYearReport($scope.year)
            .then((yearReport) => {
              $scope.yearReport = {
                data: [yearReport],
                labels: $scope.monthsOfYear,
                color: [
                  {
                    backgroundColor: "transparent",
                    borderColor: getComputedStyle(document.body).getPropertyValue("--app-color"),
                    borderWidth: 3,
                  },
                ],
              };
            })
            .finally(() => ($scope.isLoading = false));
        };
      },
    };
  },
];
