export const PrescriberDisplayController = [
  "$scope",
  "$stateParams",
  "prescriberService",
  function ($scope, $stateParams, prescriberService) {
    $scope.id = $stateParams.id;
    $scope.isLoading = true;
    prescriberService
      .getPrescriberInfo($scope.id)
      .then((prescriber) => ($scope.prescriber = prescriber))
      .finally(() => ($scope.isLoading = false));
  },
];
