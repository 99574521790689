const regexpPhone = /^(([+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6})|([0-9]{13}))$/;

export const structureService = [
  "$http",
  "toaster",
  function ($http, toaster) {
    return {
      getStructures: (application) => $http.get(`/structure/list/${application}`).then((response) => response.data),

      createStructure: (structure) => $http.post("/structure/create", structure),

      editStructure: (structure) => $http.post("/structure/update", structure),

      getStructureInfo: (id) => $http.get(`/structure/get/${id}`).then((response) => response.data),

      validateService: (newService) => {
        const errors = [];

        if (!newService.name) {
          errors.push("Le service doit posséder un nom");
        }
        if (newService.phone && !regexpPhone.test(newService.phone)) {
          errors.push("Le numéro de téléphone doit être valide");
        }
        if (newService.fax && !regexpPhone.test(newService.fax)) {
          errors.push("Le numéro de fax doit être valide");
        }

        if (errors.length) {
          toaster.warning({
            title: "Le service n'a pas pu être créé",
            body: errors.join("<br/>"),
            bodyOutputType: "trustedHtml",
          });
          return false;
        }
        return true;
      },
    };
  },
];
