import patientCreateModalTemplate from "../patient/patient-create-modal.html";
import recordValidationModalTemplate from "./record-validation-modal.html";

export const RecordCreateController = [
  "$scope",
  "$stateParams",
  "$uibModal",
  "userService",
  "$filter",
  "recordService",
  "referentielService",
  "patientService",
  "utilitiesService",
  "applicationFilterService",
  "toaster",
  "$q",
  "scrollService",
  function (
    $scope,
    $stateParams,
    $uibModal,
    userService,
    $filter,
    recordService,
    referentielService,
    patientService,
    utilitiesService,
    applicationFilterService,
    toaster,
    $q,
    scrollService
  ) {
    const userInfo = userService.getUserInfo();

    function createRecordOnServer() {
      $scope.isLoading = true;
      $scope.record.status = "Created";
      return recordService
        .save($scope.record)
        .then((salesForceRecordId) => {
          toaster.success("Le tracé a bien été créé", "Vous pouvez maintenant lui ajouter des pièces jointes");
          recordService.redirectToExecute(salesForceRecordId);
        })
        .finally(() => ($scope.isLoading = false));
    }

    function selectDiagnosticStructure(diagnosticStructureName) {
      $scope.record.interpreter.structure = $scope.referentiels.structures.find(
        (structure) => structure.name === diagnosticStructureName
      );
    }

    function selectTechnicianStructure(technicianStructureName) {
      $scope.record.technician.structure = $scope.referentiels.structures.find(
        (structure) => structure.name === technicianStructureName
      );
    }

    function validateRecord() {
      $scope.record.patho = $scope.selectedPathologies.items.map((item) => item.id).join(";");
      $scope.record.recordDate = utilitiesService.dateTimeToString(
        utilitiesService.getUtcDateTime(
          $scope.executionDateField.date,
          $scope.executionDateField.hour,
          $scope.executionDateField.min
        )
      );
      return $uibModal
        .open({
          animation: false,
          template: recordValidationModalTemplate,
          controller: "RecordValidationModalController",
          size: "lg",
          resolve: {
            record: () => $scope.record,
            attachmentFiles: () => [],
            conclusionFiles: () => [],
            proofOfAttendanceFiles: () => [],
            dangerMessage: () => null,
          },
        })
        .result.then(createRecordOnServer);
    }

    $scope.userType = userInfo.type;
    $scope.isEnabled = applicationFilterService.isEnabled;
    $scope.validationEnabled = true;
    $scope.executionDateField = { date: null, hour: null, min: null };
    $scope.selectedPathologies = { items: [] };
    $scope.record = recordService.getEmptyRecord();
    $scope.record.patient = {};
    $scope.patientResult = null;

    $scope.isLoading = true;
    $q.all(
      $stateParams.patientId
        ? {
            referentiels: referentielService.get(),
            patient: patientService.getPatientInfos($stateParams.patientId),
          }
        : { referentiels: referentielService.get() }
    )
      .then((results) => {
        if (results.patient) {
          $scope.record.patient.patientId = results.patient.patientId;
          $scope.record.patientInfos = $scope.record.patientInfos = results.patient;
        }
        $scope.referentiels = results.referentiels;
        $scope.getServices = referentielService.getServices($scope.referentiels);

        selectDiagnosticStructure("Neurophy");
        selectTechnicianStructure("Neurophy Techniciens");

        // Select the current user infos by default
        const currentUserStructure = $scope.referentiels.structures.find(
          (structure) => structure.id === userInfo.structureId
        );
        if ($scope.userType === "Nurse") {
          $scope.record.technician.structure = currentUserStructure;
        } else if ($scope.userType === "Doctor") {
          $scope.record.prescriber.structure = currentUserStructure;
          const currentUserService = $scope
            .getServices(userInfo.structureId)
            .find((service) => service.id === userInfo.servicesId[0]);
          $scope.record.prescriber.service = currentUserService;
          $scope.record.prescriber.contact = userService.getShortContact();
        }
      })
      .finally(() => ($scope.isLoading = false));

    $scope.getFormattedPatientDisplay = (patient) => {
      if (!patient) {
        return;
      }
      return `${patient.firstname} ${patient.lastname} (${$filter("date")(patient.birthdate, "dd/MM/yyyy")})${
        applicationFilterService.isEnabled("imc") ? ` - Imc: ${$scope.getImc(patient)}` : ""
      }`;
    };

    $scope.$watch("record.prescriber.service", (prescriberService) => {
      if (prescriberService) {
        referentielService
          .getPrescribers(prescriberService.id)
          .then((prescribers) => ($scope.prescribers = prescribers));
      } else {
        $scope.prescribers = [];
      }
    });

    $scope.newPatient = () => {
      const modalInstance = $uibModal.open({
        template: patientCreateModalTemplate,
        controller: "PatientCreateModalController",
        size: "lg",
      });
      return modalInstance.result.then((patient) => $scope.patientSelectAction(patient));
    };

    // Recherche d'un patient
    $scope.getPatient = patientService.getPatientFromAutocomplete;
    $scope.getImc = patientService.getImc;

    delete $scope.selectedPatient;

    $scope.formatPatient = (patient) => {
      if (patient) {
        return `${patient.firstname} ${patient.lastname} | ${$filter("date")(patient.birthdate, "dd/MM/yyyy", "UTC")}${
          patient.ssn ? ` | SSN: ${patient.ssn}` : ""
        }${applicationFilterService.isEnabled("imc") ? ` - IMC: ${$scope.getImc(patient)}` : ""}`;
      }
      return "";
    };

    $scope.patientSelectAction = (selectedPatient) => {
      $scope.selectedPatient = selectedPatient;
      if (Object.prototype.hasOwnProperty.call(selectedPatient, "patientId")) {
        $scope.record.patient.patientId =
          selectedPatient.patientId.length === 15
            ? selectedPatient.patientId
            : selectedPatient.patientId.slice(0, selectedPatient.patientId.length - 3); // WTF?!
        $scope.record.patientInfos = selectedPatient;
      }
    };

    $scope.deletePatient = () => {
      delete $scope.record.patientInfos;
      delete $scope.selectedPatient;
    };

    $scope.createRecord = () => {
      $scope.showErrorsCheckValidity = true;
      $scope.$broadcast("show-errors-check-validity");

      if ($scope.mainForm.$invalid) {
        for (const property in $scope.mainForm) {
          if (Object.prototype.hasOwnProperty.call($scope.mainForm, property) && property[0] !== "$") {
            if ($scope.mainForm[property].$valid === false) {
              scrollService.goTo(`form [name=${property}]`);
              break;
            }
          }
        }
        return;
      }
      return validateRecord();
    };
  },
];
