export const HomeInterpreterController = [
  "$scope",
  "userService",
  "recordService",
  function ($scope, userService, recordService) {
    const userInfo = userService.getUserInfo();
    const diagDoctorId = userInfo.contactId.slice(0, -3);
    const structureId = userInfo.structureId.slice(0, -3);

    $scope.isLoadingWaitingInterpretationList = true;
    const waitingInterpretationsRequest = new recordService.RecordList();
    waitingInterpretationsRequest.params.status = "Executed";
    waitingInterpretationsRequest.params.diagDoctorId = diagDoctorId;
    waitingInterpretationsRequest.params.diagDoctorType = userInfo.interpreterType;
    waitingInterpretationsRequest
      .get()
      .then((records) => ($scope.waitingInterpretationList = records))
      .finally(() => ($scope.isLoadingWaitingInterpretationList = false));

    $scope.isLoadingCreatedRecords = true;
    const createdReportsRequest = new recordService.RecordList();
    createdReportsRequest.params.status = "Created";
    createdReportsRequest.params.diagStructureId = structureId;
    createdReportsRequest.params.diagDoctorType = userInfo.interpreterType;
    createdReportsRequest
      .get()
      .then((records) => ($scope.createdRecords = records))
      .finally(() => ($scope.isLoadingCreatedRecords = false));
  },
];
