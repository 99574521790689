const apiPrefixes = ["/feedback", "/interpretations", "/patient", "/prescriber", "/record", "/user", "/structure"];
const notJsonPrefixes = ["/patient/search", "/record/save", "/record/bookmark"];

function isNotPrefixedBy(url, prefixes) {
  return prefixes.every((prefix) => !url.startsWith(prefix));
}

export const apiInterceptorFactory = [
  "$cookies",
  "userService",
  function ($cookies, userservice) {
    return {
      request: (config) => {
        if (isNotPrefixedBy(config.url, apiPrefixes)) {
          return config;
        }
        config.headers["Csrf-Token"] = $cookies.get("X-Csrf-Token");
        config.headers["X-Bios-Token"] = $cookies.get("access_token");
        // Login request exception
        if (userservice.getUserInfo()) {
          config.headers["structureId"] = userservice.getUserInfo().structureId;
          config.headers["userId"] = userservice.getUserInfo().userId;
        }
        if (config.data && isNotPrefixedBy(config.url, notJsonPrefixes)) {
          config.headers["Content-Type"] = "application/json";
        }
        config.url = config.url.replace(/\/+$/, ""); // Remove trailing slashes
        return config;
      },
    };
  },
];
