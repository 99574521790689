import { saveAs } from "file-saver";

export const RecordDisplayController = [
  "userService",
  "$q",
  "$scope",
  "$stateParams",
  "applicationFilterService",
  "interpretationsService",
  "recordService",
  function (userService, $q, $scope, $stateParams, applicationFilterService, interpretationsService, recordService) {
    const userInfo = userService.getUserInfo();

    function retrieveRemoteInterpretation() {
      return interpretationsService.getRecordInterpretation($scope.record.record.recordName);
    }

    $scope.isEnabled = applicationFilterService.isEnabled;
    $scope.getStatusLabel = recordService.getStatusLabel;
    $scope.record = recordService.getEmptyRecord();
    $scope.remoteInterpretation = {};

    $scope.ATTACHMENTS_SUB_FOLDER = recordService.ATTACHMENTS_SUB_FOLDER;
    $scope.CONCLUSION_SUB_FOLDER = recordService.CONCLUSION_SUB_FOLDER;
    $scope.CONCLUSION_REVIEW_SUB_FOLDER = recordService.CONCLUSION_REVIEW_SUB_FOLDER;
    $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER = recordService.PROOF_OF_ATTENDANCE_SUB_FOLDER;
    $scope.AUTOMATED_REPORT_SUB_FOLDER = recordService.AUTOMATED_REPORT_SUB_FOLDER;

    const recordId = $stateParams.id;
    $scope.downloadPdf = () =>
      recordService.getRecordPdf(recordId).then((result) => {
        if (result) {
          const file = new Blob([result], { type: "application/pdf" });
          saveAs(file, "Report.pdf");
        }
      });
    $scope.alterBookmark = () =>
      recordService
        .alterBookmark(recordId)
        .then(() => ($scope.record.record.isBookmarked = !$scope.record.record.isBookmarked));
    $scope.canBookmark = userInfo.type === "Interpreter";
    $scope.allowPdfDownload = false;
    $scope.isLoading = true;

    $q.all({
      record: recordService.getRecordInfos($stateParams.id),
      attachmentFiles: recordService.getRecordFiles($stateParams.id, $scope.ATTACHMENTS_SUB_FOLDER),
      conclusionFiles: recordService.getRecordFiles($stateParams.id, $scope.CONCLUSION_SUB_FOLDER),
      conclusionReviewFiles: recordService.getRecordFiles($stateParams.id, $scope.CONCLUSION_REVIEW_SUB_FOLDER),
      proofOfAttendanceFiles: $scope.isEnabled("proofOfAttendance")
        ? recordService.getRecordFiles($stateParams.id, $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER)
        : $q.resolve([]),
      automatedReportFiles:
        $scope.isEnabled("automatedReport") && ["Doctor", "Interpreter"].includes(userInfo.type)
          ? recordService.getRecordFiles($stateParams.id, $scope.AUTOMATED_REPORT_SUB_FOLDER)
          : $q.resolve([]),
    })

      .then((results) => {
        $scope.record = results.record;
        if ($scope.record.status === "Close") {
          $scope.allowPdfDownload = true;
        }

        $scope.attachmentFiles = results.attachmentFiles;
        $scope.conclusionFiles = results.conclusionFiles;
        $scope.conclusionReviewFiles = results.conclusionReviewFiles;
        $scope.proofOfAttendanceFiles = results.proofOfAttendanceFiles;

        if (results.automatedReportFiles.length) {
          $scope.automatedReportFile = results.automatedReportFiles[0].url;
        }
        if (userInfo.type === "Nurse" || userInfo.type === "NursePlus" || $scope.record.status !== "Close") {
          return $q.resolve({});
        }
        return retrieveRemoteInterpretation();
      })
      .then((remoteInterpretation) => ($scope.remoteInterpretation = remoteInterpretation))
      .finally(() => ($scope.isLoading = false));

    $scope.statusStyle = () => {
      if (!$scope.record) {
        return "label-default";
      }
      if ($scope.record.status === "Executed") {
        return "label-primary";
      }
      if ($scope.record.status === "Created") {
        return "label-info";
      }
      return "label-default";
    };
  },
];
