export const capitalizeFilter = [
  function () {
    return (input) => {
      if (!input) {
        return input;
      }
      return input.replace(/[^-\s]*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase());
    };
  },
];
