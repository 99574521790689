import template from "./somno-record-fields.directive.html";

export const somnoRecordFieldsDirective = [
  "applicationFilterService",
  function (applicationFilterService) {
    return {
      restrict: "E",
      template,
      scope: {
        somnoDetail: "=",
        isDisabled: "=",
      },
      link: ($scope) => ($scope.isEnabled = applicationFilterService.isEnabled),
    };
  },
];
