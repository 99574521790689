import template from "./interpreter-selector.directive.html";

// TODO SMS-731 That list should not be hardcoded
const centreCardiologiqueDuNordId = [
  "0010E00000GrJGYQA3", // STAGING
  "0012400000hyiF8AAI", // PROD
];

export const interpreterSelectorDirective = [
  "userService",
  "referentielService",
  function (userService, referentielService) {
    return {
      restrict: "E",
      scope: {
        record: "=record",
        isRequired: "=isRequired",
        isDisabled: "=isDisabled",
      },
      template,
      link: ($scope) => {
        const userInfo = userService.getUserInfo();

        function updateInterpretersList() {
          const previouslySelectedInterpreter = $scope.record.interpreter.contact || null;
          $scope.interpreters = [];
          $scope.record.interpreter.contact = null;

          if (
            !$scope.record.interpreter.structure ||
            !$scope.record.prescriber.structure ||
            !$scope.record.patientInfos
          ) {
            return;
          }

          const interpreterStructureId = $scope.record.interpreter.structure.id || userInfo.structureId;
          const prescriberStructureId = $scope.record.prescriber.structure.id;

          return referentielService
            .getInterpreters(prescriberStructureId, interpreterStructureId, $scope.record.patientInfos.birthdate)
            .then((interpreters) => {
              $scope.interpreters = interpreters;
              if (previouslySelectedInterpreter) {
                selectInterpreter(previouslySelectedInterpreter);
              } else if (centreCardiologiqueDuNordId.includes($scope.record.prescriber.structure.id)) {
                autoSelectVespignani();
              } else {
                selectInterpreter();
              }
            });
        }

        function selectInterpreter(interpreter) {
          if ($scope.record.patientInfos) {
            // Select interpreter if it is in the list, otherwise select first interpreter of the list
            $scope.record.interpreter.contact =
              interpreter && findInterpreterByName(interpreter.name) ? interpreter : $scope.interpreters[0];

            // Ugly quickfix to remove the Angular error still showing…
            // Launching event blur didn't work for some reason
            if ($scope.record.interpreter.contact) {
              document.querySelector("#form-interpreter").classList.remove("has-error");
            }
          }
        }

        function findInterpreterByName(interpreterName) {
          return $scope.interpreters.find(
            (interpreter) => interpreter.name.toUpperCase() === interpreterName.toUpperCase()
          );
        }

        function autoSelectVespignani() {
          $scope.record.interpreter.contact = findInterpreterByName("vespignani");
        }

        $scope.$watchGroup(
          ["record.prescriber.structure", "record.interpreter.structure", "record.patientInfos"],
          updateInterpretersList
        );

        updateInterpretersList();

        $scope.getInterpreterWithRoles = (interpreter) =>
          interpreter.name + (interpreter.interpreterType ? ` (${interpreter.interpreterType})` : "");
      },
    };
  },
];
