import angular from "angular";

export const showErrorsDirective = [
  function () {
    return {
      restrict: "A",
      require: "^form",
      link: ($scope, element, _attrs, formCtrl) => {
        function removeHasError() {
          element.toggleClass(
            "has-error",
            formCtrl[angular.element(element[0].querySelector("[name]")).attr("name")].$invalid
          );
        }

        element.on({
          keyup: removeHasError,
          change: removeHasError,
          click: removeHasError,
          input: removeHasError,
        });

        $scope.$watch("showErrorsCheckValidity", (newVal) => {
          if (!newVal) {
            return;
          }
          removeHasError();
        });
      },
    };
  },
];
