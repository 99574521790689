export const PatientCreateController = [
  "$scope",
  "$state",
  "patientService",
  "toaster",
  function ($scope, $state, patientService, toaster) {
    $scope.patient = patientService.getEmptyPatient();

    $scope.createPatient = () => {
      $scope.isLoading = true;
      return patientService
        .createPatient($scope.patient)
        .then(() => {
          toaster.success(`${$scope.patient.firstname} ${$scope.patient.lastname}`, "Le patient a été créé");
          return $state.go("patient");
        })
        .catch((response) => {
          if (response.status === 409) {
            toaster.warning({
              title: "Ce patient existe déjà",
              body: "Cliquez ici pour accéder à sa fiche",
              clickHandler: (toast) => {
                toaster.clear(toast);
                return $state.go("patient.display", { id: response.data.patientId });
              },
            });
          }
        })
        .finally(() => ($scope.isLoading = false));
    };
  },
];
