export const rangeFilter = [
  function () {
    return (input, min, max) => {
      min = parseInt(min, 10);
      max = parseInt(max, 10);
      for (let i = min; i < max; i++) {
        input.push(i);
      }
      return input;
    };
  },
];
