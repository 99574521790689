import template from "./fiche-patient.directive.html";

export const fichePatientDirective = [
  "userService",
  function (userService) {
    return {
      restrict: "E",
      scope: {
        patient: "=patient",
        submit: "=submit",
      },
      template,
      link: ($scope) => {
        $scope.currentApplication = userService.getCurrentApplication();

        $scope.birthdate = null;
        $scope.months = [
          { key: 1, value: "01 - Jan" },
          { key: 2, value: "02 - Fev" },
          { key: 3, value: "03 - Mar" },
          { key: 4, value: "04 - Avr" },
          { key: 5, value: "05 - Mai" },
          { key: 6, value: "06 - Jun" },
          { key: 7, value: "07 - Jul" },
          { key: 8, value: "08 - Aou" },
          { key: 9, value: "09 - Sep" },
          { key: 10, value: "10 - Oct" },
          { key: 11, value: "11 - Nov" },
          { key: 12, value: "12 - Dec" },
        ];

        $scope.createPatient = () => {
          $scope.patient.birthdate = Date.UTC(
            $scope.patient.birthdateYear,
            $scope.patient.birthdateMonth - 1,
            $scope.patient.birthdateDay
          ); // -14400000; //send date in UTC timezone
          $scope.patient.measure1 = parseFloat($scope.patient.measure1) || 0.0;
          $scope.patient.measure2 = parseFloat($scope.patient.measure2) || 0.0;
          $scope.submit();
        };
      },
    };
  },
];
