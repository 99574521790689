export const AdministrationController = [
  "$http",
  "$q",
  "$scope",
  "toaster",
  "recordService",
  "interpretationsService",
  function ($http, $q, $scope, toaster, recordService, interpretationsService) {
    function resolveWithLoadingIndicatorAndToaster(successMessage, ...promises) {
      setIsLoading(true);
      promises
        .reduce((previous, current) => previous.then(current), $q.resolve())
        .then(() => {
          if (successMessage) {
            toaster.success(successMessage);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    function refreshStatusAndRecords() {
      $scope.status = null;
      $scope.records = [];
      return $http
        .get("/status/interpretation")
        .then(() => interpretationsService.getAllInterpretations())
        .then((result) => {
          $scope.status = "btn-success";
          $scope.records = result;
        })
        .catch((e) => {
          $scope.status = "btn-danger";
          throw e;
        });
    }

    function setIsLoading(isLoading) {
      if ($scope.isLoading && isLoading) {
        throw Error("Interpretation API is being used already. Please wait…");
      }
      $scope.isLoading = isLoading;
    }

    $scope.getStatusLabel = recordService.getStatusLabel;
    $scope.interpretationsService = interpretationsService;

    // We use a lock here in order to not stress or corrupt the Windows machines with several concurrent commands
    $scope.isLoading = false;

    $scope.canCopyPassword = interpretationsService.canConnectAccount;

    $scope.createAccount = (record) =>
      resolveWithLoadingIndicatorAndToaster(
        `Le compte pour l'interprétation du tracé ${record.name} a été créé`,
        () => interpretationsService.createAccount(record),
        refreshStatusAndRecords
      );

    $scope.reCreateAccount = (record) =>
      resolveWithLoadingIndicatorAndToaster(
        `Le compte pour l'interprétation du tracé ${record.name} a été recréé`,
        () => interpretationsService.createAccount(record),
        refreshStatusAndRecords
      );

    $scope.reEnableAccount = (record) =>
      resolveWithLoadingIndicatorAndToaster(
        `Le compte pour l'interprétation du tracé ${record.name} a été activé`,
        () => interpretationsService.reEnableAccount(record),
        refreshStatusAndRecords
      );

    $scope.removeAccount = (record) =>
      resolveWithLoadingIndicatorAndToaster(
        `Le compte pour l'interprétation du tracé ${record.name} a été supprimé`,
        () => interpretationsService.removeAccount(record),
        refreshStatusAndRecords
      );

    $scope.removeObsoleteAccounts = () =>
      resolveWithLoadingIndicatorAndToaster(
        "La suppression des comptes obsolètes a démarré",
        interpretationsService.removeObsoleteAccounts
      );

    $scope.refreshStatus = () => resolveWithLoadingIndicatorAndToaster(null, refreshStatusAndRecords);

    $scope.refreshStatus();
  },
];
