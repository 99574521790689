import angular from "angular";

export const copyToClipboardDirective = [
  "$window",
  "$timeout",
  "toaster",
  function ($window, $timeout, toaster) {
    return {
      restrict: "A",
      link: (_scope, element) => {
        element.bind("click", () => {
          const message = element.attr("sms-copy-to-clipboard-message") || "Copié dans le presse-papier";

          // The copy command must be done from a <textarea> value, so we create a temporary one

          const body = angular.element($window.document.body);
          const textarea = angular.element("<textarea/>");
          textarea.css({
            position: "fixed",
            opacity: "0",
          });

          textarea.val(element.attr("sms-copy-to-clipboard"));
          body.append(textarea);
          textarea[0].select();

          try {
            document.execCommand("copy");
            $timeout(() => toaster.success(message));
          } finally {
            textarea.remove();
          }
        });
      },
    };
  },
];
