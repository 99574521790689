export const interceptorsConfig = [
  "$httpProvider",
  "$qProvider",
  function ($httpProvider, $qProvider) {
    // Dont throw error on promise unhandled rejections (keep angularjs 1.5 behavior)
    $qProvider.errorOnUnhandledRejections(false);

    return $httpProvider.interceptors.push("apiInterceptor", "errorInterceptor");
  },
];
