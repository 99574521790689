export const appRun = [
  "PermPermissionStore",
  "PermRoleStore",
  "userService",
  "$cookies",
  function (PermPermissionStore, PermRoleStore, userService, $cookies) {
    function isAuthenticated() {
      return Boolean($cookies.get("access_token"));
    }

    function hasRole(...roles) {
      // TODO SMS-1039 Merge type and profileName in Salesforce
      const { type, profileName } = userService.getUserInfo();
      return isAuthenticated() && roles.some((role) => type === role || profileName === role);
    }

    ["Doctor", "Interpreter", "Nurse", "NursePlus", "Structure_admin"].forEach((role) =>
      PermRoleStore.defineRole(role, hasRole)
    );
    PermPermissionStore.definePermission("isAuthenticated", isAuthenticated);
    PermPermissionStore.definePermission("canAdministrateApplications", () => hasRole("Structure_admin"));
    PermPermissionStore.definePermission("canAdministrateStructures", () => hasRole("NursePlus"));
    PermPermissionStore.definePermission("canAdministratePrescribers", () => hasRole("Nurse", "NursePlus"));
    PermPermissionStore.definePermission("canAdministrateServices", (_, transitionProperties) => {
      const { structureId } = userService.getUserInfo();
      return hasRole("NursePlus") || (hasRole("Nurse") && structureId === transitionProperties.toParams.id);
    });
    PermPermissionStore.definePermission("canDisplayStructureMenu", () => hasRole("Nurse") && !hasRole("NursePlus"));
    PermPermissionStore.definePermission("hasOverview", () => hasRole("Interpreter"));
  },
];
