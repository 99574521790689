import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import AwsS3 from "@uppy/aws-s3";
import Dashboard from "@uppy/dashboard";
import Uppy from "@uppy/core";
import template from "./uppy-upload.directive.html";
import Fr_FR from "@uppy/locales/lib/fr_FR";

const fileExtensionExclusions = [".lck"];

function isFileExcluded(fileName) {
  return fileExtensionExclusions.some((extension) => fileName.endsWith(extension));
}

function normalize(fileName) {
  return fileName.replace(/\s+/g, "_"); // replace spaces with underscore
}

export const uppyUploadDirective = [
  "$http",
  "toaster",
  function ($http, toaster) {
    return {
      restrict: "E",
      template,
      scope: {
        recordId: "@recordId",
        onUploadSuccess: "&",
        subFolder: "@",
        isUploading: "=?",
      },
      link: ($scope) => {
        localStorage.removeItem("uppyState:uppy");

        const uppy = Uppy({
          autoProceed: true,
          locale: Fr_FR,
          onBeforeFileAdded: (currentFile) => {
            if (isFileExcluded(currentFile.name)) {
              toaster.warning(`Les fichiers de type [${fileExtensionExclusions.join(", ")}] ont été ignorés.`);
              return false;
            }
            return true;
          },
        })
          .use(AwsS3, {
            getUploadParameters: (file) => {
              const subFolderPath = $scope.subFolder ? `${$scope.subFolder}/` : "";
              const normalizedFileName = normalize(file.name);
              const encodedFileName = encodeURIComponent(normalizedFileName);
              const filePath = `record${$scope.recordId}/${subFolderPath}${encodedFileName}`;
              return $http
                .post(`/record/aws/${filePath}`, {
                  body: {
                    filename: normalizedFileName,
                    contentType: file.data.type,
                  },
                })
                .then((response) => ({
                  method: response.data.method,
                  url: response.data.url,
                  fields: {},
                  headers: {
                    "Content-Type": file.data.type,
                  },
                }));
            },
          })

          .on("upload", () => ($scope.isUploading = true))
          .on("upload-success", $scope.onUploadSuccess)

          // Because canceling the last file does not trigger "onComplete" event
          .on("file-removed", () => {
            const uploadingFiles = uppy.getFiles().filter((file) => !file.progress.uploadComplete);
            if (uploadingFiles.length === 0) {
              $scope.isUploading = false;
              $scope.onUploadSuccess();
            }
          })

          .on("complete", (result) => {
            $scope.isUploading = false;
            if (result.failed.length) {
              toaster.error("Certains fichiers n'ont pas pu être envoyés", result.failed.join(", "));
            } else {
              toaster.info("Les fichiers ont bien été envoyés");
            }
          })

          .use(Dashboard, {
            height: "305px",
            width: "100%",
            target: "#upload-target",
            inline: true,
            trigger: "#upload-dashboard",
            proudlyDisplayPoweredByUppy: false,
            locale: {
              strings: {
                addingMoreFiles: "Ajouter des fichiers",
                addMore: "Ajouter des fichiers",
                browse: "cliquez ici",
                cancelUpload: "Annuler le téléchargement",
                dropPaste: "Déposez directement vos fichiers ou %{browse}",
              },
            },
          })

          .run();
      },
    };
  },
];
