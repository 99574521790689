import template from "./minute-hour-selector.directive.html";

export const minuteHourSelectorDirective = [
  function () {
    return {
      restrict: "E",
      scope: {
        minute: "=minute",
        hour: "=hour",
        isRequired: "=isRequired",
        isDisabled: "=isDisabled",
      },
      template,
      link: ($scope) => {
        $scope.hours = [...Array(24).keys()];
        $scope.minutes = [...Array(60).keys()];

        $scope.updateMinutes = () => {
          if (!$scope.hour) {
            $scope.minute = "";
          }
        };
      },
    };
  },
];
