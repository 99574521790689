export const errorInterceptorFactory = [
  "$q",
  "userService",
  "toaster",
  "$log",
  function ($q, userService, toaster, $log) {
    return {
      responseError: (response) => {
        if (response.status === 401 || (response.status === 400 && response.data.status === 401)) {
          userService.logIn();
        } else if (response.config && response.config.ignoreStatus !== response.status) {
          $log.error(response);
          const content = response.data
            ? response.data.error
              ? response.data.error
              : JSON.stringify(response.data)
            : "";
          toaster.error({
            title: `Une erreur s'est produite sur la requête ${response.config.method} ${response.config.url}`,
            body: `<div>Erreur ${response.status} : ${response.statusText}</div>` + `<div>${content}</div>`,
            bodyOutputType: "trustedHtml",
          });
        }
        return $q.reject(response);
      },
    };
  },
];
