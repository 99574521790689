import administrationTemplate from "../controllers/administration/administration.html";
import contactTemplate from "../controllers/contact/contact.html";
import debugTemplate from "../controllers/debug/debug.html";
import feedbackTemplate from "../controllers/feedback/feedback.html";
import homeInterpreterTemplate from "../controllers/home/home-interpreter.html";
import homePrescriberTemplate from "../controllers/home/home-prescriber.html";
import homeTechnicianTemplate from "../controllers/home/home-technician.html";
import overviewInterpreterTemplate from "../controllers/overview/overview-interpreter.html";
import patientCreateTemplate from "../controllers/patient/patient-create.html";
import patientDisplayTemplate from "../controllers/patient/patient-display.html";
import patientEditTemplate from "../controllers/patient/patient-edit.html";
import patientSearchTemplate from "../controllers/patient/patient-search.html";
import prescriberCreateTemplate from "../controllers/prescriber/prescriber-create.html";
import prescriberDisplayTemplate from "../controllers/prescriber/prescriber-display.html";
import prescriberSearchTemplate from "../controllers/prescriber/prescriber-search.html";
import recordCreateTemplate from "../controllers/record/record-create.html";
import recordDisplayTemplate from "../controllers/record/record-display.html";
import recordExecuteTemplate from "../controllers/record/record-execute.html";
import recordInterpretTemplate from "../controllers/record/record-interpret.html";
import recordSearchTemplate from "../controllers/record/record-search.html";
import structureCreateTemplate from "../controllers/structure/structure-create.html";
import structureDisplayTemplate from "../controllers/structure/structure-display.html";
import structureEditTemplate from "../controllers/structure/structure-edit.html";
import structureSearchTemplate from "../controllers/structure/structure-search.html";

function dataPermissions(permission) {
  return {
    permissions: {
      only: permission,
      redirectTo: "home",
    },
  };
}

// Ensure userInfo are resolved
const resolveUserInfo = {
  userInfo: [
    "userService",
    function (userService) {
      return userService.resolveUserInfo();
    },
  ],
};

export const statesConfig = [
  "$locationProvider",
  "$stateProvider",
  "$urlRouterProvider",
  function ($locationProvider, $stateProvider, $urlRouterProvider) {
    $locationProvider.hashPrefix("");

    // Administration

    $stateProvider
      .state("administration", {
        url: "/administration",
        data: dataPermissions("canAdministrateApplications"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: administrationTemplate,
            controller: "AdministrationController",
          },
        },
      })

      // Contact

      .state("contact", {
        url: "/contact",
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: contactTemplate,
          },
        },
      })

      // Debug

      .state("debug", {
        url: "/debug",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: debugTemplate,
            controller: "DebugController",
          },
        },
      })

      // Feedback

      .state("feedback", {
        url: "/feedback",
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: feedbackTemplate,
            controller: "FeedbackController",
          },
        },
      })

      // Home

      .state("home", {
        url: "/",
        resolve: resolveUserInfo,
        views: {
          "!main": {
            controller: "HomeController",
          },
        },
      })
      .state("homePrescriber", {
        url: "/home/prescriber",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: homePrescriberTemplate,
            controller: "HomePrescriberController",
          },
        },
      })
      .state("homeTechnician", {
        url: "/home/technician",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: homeTechnicianTemplate,
            controller: "HomeTechnicianController",
          },
        },
      })
      .state("homeInterpreter", {
        url: "/home/interpreter",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: homeInterpreterTemplate,
            controller: "HomeInterpreterController",
          },
        },
      })

      // Login callback

      .state("login", {
        url: "/login/:access_token/:instance_url",
        views: {
          "!main": {
            controller: "LoginCallbackController",
          },
        },
      })

      // Overview

      .state("overviewInterpreter", {
        url: "/overview/interpreter",
        data: dataPermissions("hasOverview"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: overviewInterpreterTemplate,
            controller: "OverviewInterpreterController",
          },
        },
      })

      // Patient

      .state("patient", {
        url: "/patient",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: patientSearchTemplate,
            controller: "PatientSearchController",
          },
        },
      })
      .state("patient.create", {
        url: "/create",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: patientCreateTemplate,
            controller: "PatientCreateController",
          },
        },
      })
      .state("patient.display", {
        url: "/display/:id",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: patientDisplayTemplate,
            controller: "PatientDisplayController",
          },
        },
      })
      .state("patient.edit", {
        url: "/edit/:id",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: patientEditTemplate,
            controller: "PatientEditController",
          },
        },
      })

      // Prescriber

      .state("prescriber", {
        url: "/prescriber",
        data: dataPermissions("canAdministratePrescribers"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: prescriberSearchTemplate,
            controller: "PrescriberSearchController",
          },
        },
      })
      .state("prescriber.create", {
        url: "/create",
        data: dataPermissions("canAdministratePrescribers"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: prescriberCreateTemplate,
            controller: "PrescriberCreateController",
          },
        },
      })
      .state("prescriber.display", {
        url: "/display/:id",
        data: dataPermissions("canAdministratePrescribers"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: prescriberDisplayTemplate,
            controller: "PrescriberDisplayController",
          },
        },
      })

      // Record

      .state("record", {
        url: "/record",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: recordSearchTemplate,
            controller: "RecordSearchController",
          },
        },
      })
      .state("record.create", {
        url: "/create?patientId",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: recordCreateTemplate,
            controller: "RecordCreateController",
          },
        },
      })
      .state("record.display", {
        url: "/display/:id",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: recordDisplayTemplate,
            controller: "RecordDisplayController",
          },
        },
      })
      .state("record.execute", {
        url: "/execute/:id",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: recordExecuteTemplate,
            controller: "RecordExecuteController",
          },
        },
      })
      .state("record.interpret", {
        url: "/interpret/:id",
        data: dataPermissions("isAuthenticated"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: recordInterpretTemplate,
            controller: "RecordInterpretController",
          },
        },
      })

      // Structure

      .state("structure", {
        url: "/structure",
        redirectTo: "structure.search",
      })
      .state("structure.search", {
        url: "",
        data: dataPermissions("canAdministrateStructures"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: structureSearchTemplate,
            controller: "StructureSearchController",
          },
        },
      })
      .state("structure.create", {
        url: "/create",
        data: dataPermissions("canAdministrateStructures"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: structureCreateTemplate,
            controller: "StructureCreateController",
          },
        },
      })
      .state("structure.display", {
        url: "/display/:id",
        data: dataPermissions("canAdministrateServices"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: structureDisplayTemplate,
            controller: "StructureDisplayController",
          },
        },
      })
      .state("structure.edit", {
        url: "/edit/:id",
        data: dataPermissions("canAdministrateServices"),
        resolve: resolveUserInfo,
        views: {
          "!main": {
            template: structureEditTemplate,
            controller: "StructureEditController",
          },
        },
      });

    $urlRouterProvider.otherwise(($injector) => {
      const $state = $injector.get("$state");
      $state.go("home");
    });
  },
];
