import template from "./fiche-record.directive.html";
import warningModalTemplate from "../controllers/warning/warning-modal.html";

export const ficheRecordDirective = [
  "userService",
  "$uibModal",
  "$window",
  "applicationFilterService",
  "recordService",
  "interpretationsService",
  "$q",
  "toaster",
  function (
    userService,
    $uibModal,
    $window,
    applicationFilterService,
    recordService,
    interpretationsService,
    $q,
    toaster
  ) {
    return {
      restrict: "E",
      scope: {
        attachmentFiles: "=",
        conclusionFiles: "=",
        conclusionReviewFiles: "=",
        proofOfAttendanceFiles: "=",
        isLoading: "=",
        isReadOnly: "=",
        record: "=",
        remoteInterpretation: "=",
      },
      template,
      link: ($scope) => {
        const userInfo = userService.getUserInfo();
        $scope.isEnabled = applicationFilterService.isEnabled;
        $scope.isTechnician = userInfo.type === "Nurse" || userInfo.type === "NursePlus";
        $scope.playback = null;
        $scope.interpretationsService = interpretationsService;

        $scope.ATTACHMENTS_SUB_FOLDER = recordService.ATTACHMENTS_SUB_FOLDER;
        $scope.CONCLUSION_SUB_FOLDER = recordService.CONCLUSION_SUB_FOLDER;
        $scope.CONCLUSION_REVIEW_SUB_FOLDER = recordService.CONCLUSION_REVIEW_SUB_FOLDER;
        $scope.CONSENT_TEXT = recordService.CONSENT_TEXT;
        $scope.RD_CONSENT_TEXT = recordService.RD_CONSENT_TEXT;
        $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER = recordService.PROOF_OF_ATTENDANCE_SUB_FOLDER;
        $scope.isUploading = { fromLocal: false, fromRemote: false };

        $scope.reloadProofOfAttendance = () =>
          recordService
            .getRecordFiles($scope.record.record.recordId, $scope.PROOF_OF_ATTENDANCE_SUB_FOLDER)
            .then((files) => ($scope.proofOfAttendanceFiles = files));

        const ACCOUNT_ACTIONS = {
          CONNECT: "connect",
          ENABLE: "enable",
          CREATE: "create",
        };

        function getAccountAction(remoteInterpretation) {
          if (interpretationsService.canConnectAccount(remoteInterpretation)) {
            return ACCOUNT_ACTIONS.CONNECT;
          }
          if (interpretationsService.canReEnableAccount(remoteInterpretation)) {
            return ACCOUNT_ACTIONS.ENABLE;
          }
          if (interpretationsService.canCreateAccount(remoteInterpretation)) {
            return ACCOUNT_ACTIONS.CREATE;
          }
        }

        $scope.shouldDisplayRemoteInterpretation = (remoteInterpretation) =>
          Boolean(getAccountAction(remoteInterpretation));

        $scope.shouldAllowConclusionReviewUpload = () => Boolean(userInfo.type === "Doctor");

        $scope.shouldDisplayConnection = (remoteInterpretation) =>
          [ACCOUNT_ACTIONS.CONNECT, ACCOUNT_ACTIONS.ENABLE].includes(getAccountAction(remoteInterpretation));

        $scope.shouldDisplayCreation = (remoteInterpretation) =>
          ACCOUNT_ACTIONS.CREATE === getAccountAction(remoteInterpretation);

        $scope.connectToAccount = function (remoteInterpretation) {
          const connectionFlow = [];

          if (userService.getCurrentApplication() === "SOMNOPHY") {
            connectionFlow.unshift(confirmPreviousAnnotationsWillBeErased);
          }

          connectionFlow.unshift(() => connectToRemoteDesktop(remoteInterpretation));

          if (getAccountAction(remoteInterpretation) === ACCOUNT_ACTIONS.ENABLE) {
            connectionFlow.unshift(() => enableAccount(remoteInterpretation));
          }

          connectionFlow.reduce((previous, current) => previous.then(() => current()), $q.resolve());
        };

        $scope.createAccount = function (remoteInterpretation) {
          $scope.isLoading = true;
          return interpretationsService
            .createAccount(remoteInterpretation)
            .then((result) => {
              $scope.remoteInterpretation = result.data;
              toaster.success("Le bureau à distance a été créé. Vous pouvez maintenant vous connecter.");
            })
            .finally(() => {
              $scope.isLoading = false;
            });
        };

        $scope.uploadConclusionFromRemoteDesktop = () => {
          $scope.isUploading.fromRemote = true;
          return interpretationsService
            .uploadConclusionFromRemoteDesktop($scope.record.record.recordName)
            .then(() => {
              $scope.reloadUploads();
              toaster.success(
                "Les conclusions de l'interprétation ont bien été téléchargées",
                "Vous pouvez maintenant valider l'interprétation"
              );
            })
            .catch((error) => {
              if (error.data === "CONCLUSION_NOT_FOUND") {
                toaster.warning("Aucune conclusion n'a été trouvée sur le bureau à distance");
              } else {
                return $q.reject(error);
              }
            })
            .finally(() => ($scope.isUploading.fromRemote = false));
        };

        $scope.reloadUploads = () => {
          recordService
            .getRecordFiles($scope.record.record.recordId, $scope.CONCLUSION_REVIEW_SUB_FOLDER)
            .then((conclusionReviewFiles) => ($scope.conclusionReviewFiles = conclusionReviewFiles));
        };

        $scope.getUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        function enableAccount(remoteInterpretation) {
          $scope.isLoading = true;
          return interpretationsService.reEnableAccount(remoteInterpretation).finally(() => ($scope.isLoading = false));
        }

        function confirmPreviousAnnotationsWillBeErased() {
          return $uibModal.open({
            animation: false,
            template: warningModalTemplate,
            controller: "WarningModalController",
            size: "md",
            resolve: {
              message: () =>
                "Les modifications que vous réaliserez sur le bureau à distance <strong>écraseront les annotations précédentes</strong>.",
            },
          }).result;
        }

        function connectToRemoteDesktop(remoteInterpretation) {
          $window.location = interpretationsService.connectAccount(remoteInterpretation);
        }

        $scope.pathoList = [];
        $scope.$watch(
          "record",
          () => {
            if ($scope.record.patho) {
              $scope.pathoList = $scope.record.patho.split(";");
            }
          },
          true
        );
      },
    };
  },
];
