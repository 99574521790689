import template from "./technician-selector.directive.html";

export const technicianSelectorDirective = [
  "referentielService",
  "utilitiesService",
  "userService",
  function (referentielService, utilitiesService, userService) {
    const userInfo = userService.getUserInfo();
    return {
      restrict: "E",
      scope: {
        record: "=record",
        executionDateField: "=executionDateField",
        isRequired: "=isRequired",
        isDisabled: "=isDisabled",
        model: "=model",
      },
      template,
      link: ($scope) => {
        function updateTechniciansList() {
          const previouslySelectedTechnician = $scope.record.technician.contact || null;
          $scope.technicians = [];
          $scope.record.technician.contact = null;

          if (!$scope.record.technician.structure || !$scope.executionDateField.date || !$scope.record.patientInfos) {
            return;
          }

          return referentielService
            .getTechnicians(
              $scope.record.technician.structure.id,
              getRecordExecutionDate(),
              $scope.record.patientInfos.birthdate
            )
            .then((technicians) => {
              $scope.technicians = technicians;
              selectTechnician(
                previouslySelectedTechnician ? previouslySelectedTechnician : findTechnicianById(userInfo.contactId)
              );
            });
        }

        function getRecordExecutionDate() {
          return utilitiesService.getUtcDateTime(
            $scope.executionDateField.date,
            $scope.executionDateField.hour,
            $scope.executionDateField.min
          );
        }

        function selectTechnician(technician) {
          if (!$scope.record.technician.structure || !$scope.record.patientInfos) {
            return;
          }
          // Select technician if it is in the list, otherwise select first technician of the list
          $scope.record.technician.contact =
            technician && findTechnicianById(technician.id) ? technician : $scope.technicians[0];

          // Ugly quickfix to remove the Angular error still showing…
          // Launching event blur didn't work for some reason
          if ($scope.record.technician.contact) {
            document.querySelector("#form-technician").classList.remove("has-error");
          }
        }

        function findTechnicianById(technicianId) {
          return $scope.technicians.find((technician) => technician.id === technicianId);
        }

        updateTechniciansList();

        $scope.$watchGroup(
          ["record.technician.structure", "executionDateField.date", "executionDateField.hour", "record.patientInfos"],
          updateTechniciansList
        );
      },
    };
  },
];
