export const HomePrescriberController = [
  "$scope",
  "userService",
  "recordService",
  function ($scope, userService, recordService) {
    const userInfo = userService.getUserInfo();
    const prescriberId = userInfo.contactId.slice(0, -3);

    $scope.isLoadingWaitingInterpretations = true;
    const waitingInterpretations = new recordService.RecordList();
    waitingInterpretations.params.status = "Created";
    waitingInterpretations.params.prescriberId = prescriberId;
    waitingInterpretations
      .get()
      .then((records) => ($scope.waitingInterpretations = records))
      .finally(() => ($scope.isLoadingWaitingInterpretations = false));

    $scope.isLoadingLastInterpretations = true;
    const lastInterpretations = new recordService.RecordList();
    lastInterpretations.params.status = "Close";
    lastInterpretations.params.prescriberId = prescriberId;
    lastInterpretations
      .get()
      .then((records) => ($scope.lastInterpretations = records))
      .finally(() => ($scope.isLoadingLastInterpretations = false));
  },
];
