import template from "./date-selector.directive.html";

export const dateSelectorDirective = [
  function () {
    return {
      restrict: "E",
      scope: {
        date: "=",
        isRequired: "=",
        customPlaceholder: "@",
      },

      template,

      link: ($scope) => {
        $scope.dateOptions = {
          formatYear: "yy",
          startingDay: 1,
        };
        $scope.open = () => ($scope.popupOpened = true);
        $scope.popupOpened = false;
      },
    };
  },
];
