export const DebugController = [
  "$scope",
  "userService",
  "$cookies",
  function ($scope, userService, $cookies) {
    $scope.cookie = $cookies.getAll();

    $scope.environments = {
      Local: "http://localhost:9000",
    };

    $scope.userInfo = userService.getUserInfo();

    $scope.loginUrl = `#/login/${$scope.cookie.access_token}/${$scope.cookie.instance_url}`;
  },
];
