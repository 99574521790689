const somnophyActTypes = [
  "Libre cours",
  "Polygraphie Ventilatoire",
  "Polysomnographie de 12 heures, sans enregistrement vidéo",
  "PSG + Video",
  "TILE (tests itératifs de latence d'endormissement)",
  "TME (tests itératifs de maintien d'éveil)",
];

const somnophyPathologies = [
  "Appareillage d’un syndrome d’apnées par PPC",
  "Appareillage d’un syndrome d’apnées par SVA",
  "Bilan sommeil général",
  "Contrôle d’efficacité du port d’orthèse d’avancée mandibulaire",
  "Épilepsie nocturne",
  "Évaluation de l’efficacité d’un appareillage par PPC",
  "Hypersomnie",
  "Mouvements périodiques de jambes",
  "Narcolepsie",
  "Parasomnies du sommeil lent profond",
  "Syndrome d’apnées",
  "Syndrome d’apnées centrales",
  "Syndrome d’apnées complexe",
  "Troubles du comportement en sommeil paradoxal",
];

const somnophyInterpretationSoftwares = [
  "Cadwell",
  "CleveMed",
  "Compumedics PSG",
  "Contec",
  "Curative",
  "Lowenstein",
  "Micromed",
  "Natus",
  "NCC",
  "Neurosoft PSG",
  "Neurovirtual PSG",
  "Nox",
  "Philips",
];

const labels = {
  SOMNOPHY: {
    newDemandListTitle: "Nouvelles demandes de PSG",
    urgentHtml: "<strong>Demande d'interprétation sous 6 heures</strong>",
  },
  TELEMED: {
    newDemandListTitle: "Nouvelles demandes d'EEG",
    urgentHtml: "<strong>Tracé urgent </strong><em> (Cas de mort encéphalique)</em>",
  },
};

const commonInterpretationSoftwares = ["Autre"];

const features = {
  SOMNOPHY: {
    automatedReport: false,
    autoSelectActType: false,
    conclusionPdfDownload: false,
    imc: true,
    interpreterConclusionUpload: true,
    prestationTypeField: false,
    electrodeNumberField: false,
    recordPlaceField: false,
    somnoRecordFields: true,
    proofOfAttendance: false,
  },
  TELEMED: {
    automatedReport: true,
    autoSelectActType: true,
    conclusionPdfDownload: true,
    imc: false,
    interpreterConclusionUpload: false,
    prestationTypeField: true,
    electrodeNumberField: true,
    recordPlaceField: true,
    somnoRecordFields: false,
    proofOfAttendance: true,
  },
};

export const applicationFilterService = [
  "userService",
  function (userService) {
    const currentApplication = userService.getCurrentApplication();

    function filterList(list = [], somnophyList = [], commonList = []) {
      return list.filter(
        (item) =>
          commonList.includes(item.name) ||
          (currentApplication !== "SOMNOPHY" && !somnophyList.includes(item.name)) ||
          (currentApplication === "SOMNOPHY" && somnophyList.includes(item.name))
      );
    }

    function isEntityInApplication(entity) {
      return entity.application ? entity.application.split(";").indexOf(currentApplication) > -1 : false;
    }

    return {
      filterActTypes: (actTypes) => filterList(actTypes, somnophyActTypes),

      filterPathologies: (pathologies) => filterList(pathologies, somnophyPathologies),

      filterInterpretationSoftwares: (interpretationSoftwares) =>
        filterList(interpretationSoftwares, somnophyInterpretationSoftwares, commonInterpretationSoftwares),

      filterContactsOrStructures: (contactsOrStructures) => contactsOrStructures.filter(isEntityInApplication),

      isEntityInApplication,

      getLabel: (name) => labels[currentApplication][name],

      isEnabled: (featureName) => (currentApplication ? features[currentApplication][featureName] : false),
    };
  },
];
