export const $exceptionHandlerFactory = [
  "$injector",
  "$log",
  function ($injector, $log) {
    // Injected that way because of a circular dependency
    function inject(name) {
      return $injector.get(name, "$exceptionHandlerFactory");
    }

    return (exception, cause) => {
      const content = exception.message || exception.data || exception;
      inject("toaster").error(`Une erreur s'est produite (${content})`, cause);
      $log.error(exception, cause);
    };
  },
];
