export const PatientDisplayController = [
  "$scope",
  "$stateParams",
  "userService",
  "patientService",
  "recordService",
  function ($scope, $stateParams, userService, patientService, recordService) {
    $scope.currentApplication = userService.getCurrentApplication();

    if ($stateParams.id) {
      $scope.patientId = $stateParams.id;
    } else {
      $scope.patientId = "personne";
    }

    $scope.isLoading = true;
    patientService
      .getPatientInfos($scope.patientId)
      .then((patient) => ($scope.patient = patient))
      .finally(() => ($scope.isLoading = false));

    $scope.isLoadingRecords = true;
    const patientRecords = new recordService.RecordList();
    patientRecords.params.idPatient = $scope.patientId;
    patientRecords
      .get()
      .then((records) => ($scope.patientsRecords = records))
      .finally(() => ($scope.isLoadingRecords = false));
  },
];
