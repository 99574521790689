export const WarningModalController = [
  "$scope",
  "$uibModalInstance",
  "message",
  function ($scope, $uibModalInstance, message) {
    $scope.message = message;

    $scope.ok = () => $uibModalInstance.close();
  },
];
