export const uiSelectPropertyFilter = [
  function () {
    return (items, props) => {
      let out = [];

      if (Array.isArray(items)) {
        const keys = Object.keys(props);

        items.forEach((item) => {
          const itemMatches = keys.find((key) => item[key].toLowerCase().includes(props[key].toLowerCase()));
          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  },
];
