let userInfo = null;

export const userService = [
  "$cookies",
  "$q",
  "$rootScope",
  "$injector",
  "$filter",
  "$window",
  function ($cookies, $q, $rootScope, $injector, $filter, $window) {
    $rootScope.isAuthenticated = false;

    // Injected that way because of a circular dependency
    function inject(name) {
      return $injector.get(name, "userService");
    }

    function clearUserData() {
      Object.keys($cookies.getAll()).forEach((key) => $cookies.remove(key));
      userInfo = null;
    }

    function getAccessToken() {
      return $cookies.get("access_token");
    }

    function getCurrentApplication() {
      return $cookies.get("currentApplication");
    }

    function getUserApplications() {
      return userInfo.applications ? userInfo.applications.split(";") : [];
    }

    function logIn() {
      return inject("$http")
        .get("/url/login")
        .then((url) => ($window.location.href = url.data));
    }

    function logInCallback(data) {
      if (!data.access_token) {
        return logIn();
      }

      clearUserData();

      ["access_token", "instance_url"].forEach((key) => {
        $cookies.put(key, data[key] || null);
      });

      return resolveUserInfo()
        .then(() => redirectToUserHome())
        .catch(() => logOut());
    }

    function logOut() {
      clearUserData();
      return logIn();
    }

    function redirectToUserHome() {
      const state = userHomeState();
      return state ? inject("$state").go(state) : logOut();
    }

    function resolveUserInfo() {
      if (userInfo) {
        return $q.resolve(userInfo);
      }

      return inject("$http")
        .get("/user")
        .then((response) => {
          userInfo = {};
          userInfo.firstName = $filter("capitalize")(response.data.firstName);
          userInfo.lastName = $filter("uppercase")(response.data.lastName);
          // TODO SMS-1039 Merge type and profileName in Salesforce
          userInfo.type = response.data.contactType;
          userInfo.profileName = response.data.profileName;
          userInfo.structureId = response.data.structureId;
          userInfo.servicesId = response.data.servicesId;
          userInfo.userId = response.data.userId;
          userInfo.contactId = response.data.contactId;
          userInfo.applications = response.data.applications;
          userInfo.salutation = response.data.salutation;
          userInfo.interpreterType = response.data.interpreterType;
          userInfo.isInternal = response.data.isInternal;
          setCurrentApplication(getCurrentApplication() || getUserApplications()[0]);

          $rootScope.isAuthenticated = true;
          $rootScope.userHomeState = userHomeState();
          $rootScope.structureId = userInfo.structureId;

          return userInfo;
        });
    }

    function setCurrentApplication(newCurrentApplication) {
      $cookies.put("currentApplication", newCurrentApplication);

      Object.entries({
        "--app-color": { TELEMED: "#31c2d1", SOMNOPHY: "#0524c1" },
        "--title-color": { TELEMED: "#616161", SOMNOPHY: "#616161" },
        "--primary-btn-color": { TELEMED: "#1563d1", SOMNOPHY: "#31c2d1" },
        "--btn-border-color": { TELEMED: "#0f4386", SOMNOPHY: "#2999a5" },
        "--btn-hover-color": { TELEMED: "#134589", SOMNOPHY: "#237b88" },
        "--btn-border-hover-color": { TELEMED: "#0d3264", SOMNOPHY: "#1b5e68" },
        "--btn-active-color": { TELEMED: "#1758af", SOMNOPHY: "#1a5e6a" },
        "--btn-border-active-color": { TELEMED: "#0a2245", SOMNOPHY: "#15434c" },
        "--panel-heading-color": { TELEMED: "#31c2d1", SOMNOPHY: "#2c47d1" },
      }).forEach((entry) => document.documentElement.style.setProperty(entry[0], entry[1][newCurrentApplication]));
    }

    function userHomeState() {
      return {
        Doctor: "homePrescriber",
        Interpreter: "homeInterpreter",
        Nurse: "homeTechnician",
        NursePlus: "homeTechnician",
        Structure_admin: "home",
      }[userInfo.type];
    }

    // TODO refactoring userInfo to match this ShortContact model
    function getShortContact() {
      return {
        id: userInfo.contactId,
        application: userInfo.applications,
        name: `${userInfo.salutation} ${userInfo.firstName} ${userInfo.lastName}`,
        contactType: userInfo.type,
        interpreterType: userInfo.interpreterType,
      };
    }

    return {
      clearUserData,
      getAccessToken,
      getCurrentApplication,
      getUserApplications,
      getUserInfo: () => userInfo,
      getShortContact,
      logIn,
      logInCallback,
      logOut,
      redirectToUserHome,
      resolveUserInfo,
      setCurrentApplication,
      userHomeState,
    };
  },
];
