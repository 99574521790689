import template from "./month-report.directive.html";

const monthReportLabels = ["U", "P", "S", "J", "F"];

export const monthReportDirective = [
  "recordService",
  "utilitiesService",
  function (recordService, utilitiesService) {
    return {
      restrict: "E",
      scope: {
        month: "=",
        year: "=",
      },
      template,
      link: ($scope) => {
        function getMonthReport() {
          $scope.isLoading = true;
          return recordService
            .getInterpreterMonthReport($scope.month, $scope.year)
            .then((monthReport) => displayMonthReport(monthReport))
            .finally(() => ($scope.isLoading = false));
        }

        function displayMonthReport(monthReport) {
          const data = [monthReport.u, monthReport.p, monthReport.s, monthReport.j, monthReport.f];
          $scope.monthReport = {
            data: [data],
            labels: monthReportLabels,
            color: [
              {
                backgroundColor: getComputedStyle(document.body).getPropertyValue("--app-color"),
                borderColor: getComputedStyle(document.body).getPropertyValue("--app-color"),
              },
            ],
          };
        }

        $scope.$watchGroup(["year", "month"], getMonthReport);

        $scope.getMonthLabel = (month) => utilitiesService.getMonthList()[month - 1];
      },
    };
  },
];
