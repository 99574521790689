export const StructureSearchController = [
  "$scope",
  "userService",
  "structureService",
  function ($scope, userService, structureService) {
    $scope.isLoading = true;
    structureService
      .getStructures(userService.getCurrentApplication())
      .then((result) => ($scope.structuresList = result))
      .finally(() => ($scope.isLoading = false));
  },
];
