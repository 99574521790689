import template from "./list-records.directive.html";

function dateSortFn(a, b) {
  if (a === "Invalid Date") {
    return -1;
  }
  if (b === "Invalid Date") {
    return 1;
  }
  if (a === b) {
    return 0;
  }
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
}

export const listRecordsDirective = [
  "recordService",
  function (recordService) {
    return {
      restrict: "E",
      scope: {
        list: "=",
        listTitle: "@",
        showStatus: "=?",
        showInterpreter: "=?",
        displayedDate: "@",
        displayedDateLabel: "@",
        editionColumnOptions: "@", // view, edit, interpret or/and pushProofOfAttendance
        isLoading: "=",
      },
      template,
      link: ($scope) => {
        $scope.showStatus = $scope.showStatus !== false; // Defaults to true
        $scope.showInterpreter = $scope.showInterpreter || false; // Defaults to false
        $scope.userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if ($scope.showStatus) {
          $scope.$watch("list", () => {
            if (!$scope.list) {
              return;
            }
            $scope.list.forEach((record) => (record.statusLabel = recordService.getStatusLabel(record)));
          });
        }

        $scope.columnDefs = [
          { name: "recordName", displayName: "ID", width: 80, suppressRemoveSort: true },
          { name: "patientName", displayName: "Prénom", suppressRemoveSort: true },
          { name: "patientFirstName", displayName: "Nom", suppressRemoveSort: true },
          { name: "actType", displayName: "Type d'acte", suppressRemoveSort: true },
          {
            name: $scope.displayedDate ? $scope.displayedDate : "recordDate",
            displayName: $scope.displayedDateLabel ? $scope.displayedDateLabel : "Prévu le",
            type: "date",
            cellFilter: $scope.displayedDate
              ? `date:'dd/MM/yyyy HH:mm:ss':${$scope.userTimezone}`
              : "date:'dd/MM/yyyy HH:mm:ss':'UTC'",
            suppressRemoveSort: true,
            sortingAlgorithm: dateSortFn,
          },
          { name: "prescriptionStructure", displayName: "Lieu de prescription", suppressRemoveSort: true },
          {
            name: "interpreter",
            displayName: "Interprétateur",
            suppressRemoveSort: true,
            visible: $scope.showInterpreter,
          },
          {
            name: "statusLabel",
            displayName: "Statut",
            width: 80,
            suppressRemoveSort: true,
            visible: $scope.showStatus,
          },
          {
            enableSorting: false,
            name: "Édition",
            displayName: "",
            width: 80,
            cellTemplate: buildEditionColumn($scope.editionColumnOptions),
          },
        ];

        function buildEditionColumn(editionColumnOptions) {
          let editionColumnTemplate = '<div class="ui-grid-vcenter">';

          if (editionColumnOptions.includes("view")) {
            editionColumnTemplate +=
              '  <a ui-sref="record.display({id: row.entity.recordId})">' +
              '    <button type="button" class="btn btn-xs btn-default" title="Visualiser">' +
              '      <span class="glyphicon glyphicon-eye-open"></span>' +
              "    </button>" +
              "  </a>";
          }

          if (editionColumnOptions.includes("edit")) {
            editionColumnTemplate +=
              '  <a ui-sref="record.execute({id: row.entity.recordId})">' +
              '    <button type="button" class="btn btn-primary btn-xs">Éditer</button>' +
              "  </a>";
          }

          if (editionColumnOptions.includes("interpret")) {
            editionColumnTemplate +=
              '  <a ui-sref="record.interpret({id: row.entity.recordId})">' +
              '    <button type="button" class="btn btn-xs btn-primary" ng-class="{\'btn-primary\' : !row.entity.isUrgent, \'btn-danger\' : row.entity.isUrgent}">' +
              "      Interpréter" +
              "    </button>" +
              "  </a>";
          }

          if (editionColumnOptions.includes("pushProofOfAttendance")) {
            editionColumnTemplate +=
              '  <a ui-sref="record.display({id: row.entity.recordId})">' +
              '    <button type="button" class="btn btn-primary btn-xs">Ajouter</button>' +
              "  </a>";
          }

          editionColumnTemplate += "</div>";
          return editionColumnTemplate;
        }
      },
    };
  },
];
