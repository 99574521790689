export const RecordValidationModalController = [
  "$scope",
  "$uibModalInstance",
  "attachmentFiles",
  "conclusionFiles",
  "proofOfAttendanceFiles",
  "record",
  "toaster",
  "dangerMessage",
  function (
    $scope,
    $uibModalInstance,
    attachmentFiles,
    conclusionFiles,
    proofOfAttendanceFiles,
    record,
    toaster,
    dangerMessage
  ) {
    $scope.attachmentFiles = attachmentFiles;
    $scope.conclusionFiles = conclusionFiles;
    $scope.proofOfAttendanceFiles = proofOfAttendanceFiles;
    $scope.record = record;
    $scope.dangerMessage = dangerMessage;

    $scope.cancel = () => $uibModalInstance.dismiss();

    $scope.ok = () => {
      if (
        $scope.record.status === "Created" &&
        $scope.attachmentFiles.length === 0 &&
        $scope.record.interpretationLink === ""
      ) {
        toaster.warning("Le tracé n'a pas pu être validé", "Au moins un fichier doit être joint au tracé");
        return;
      }
      $uibModalInstance.close($scope.record);
    };
  },
];
